import React, { useState, useEffect } from "react";
import { Image, Spin, notification, Modal } from 'antd';
import { questionsConfig } from "./questionsConfig";
import Review from "./ReviewPage/Review";
import QuestionStep from "./SingleChoiceQuestions/SingleChoiceStep";
import MultiChoiceStep from "./MultipleChoiceQuestions/MultiChoiceStep";
import DropDownStepMultipleSelect from "./DropDownQuestions/DropDownStepMultipleSelect";
import DropDownStepSingleSelect from "./DropDownQuestions/DropDownStepSingleSelect";
import TextInputStep from "./InputTypeText/TextInputStep";
import QuestionStepValidation from "./ValidationTypeQuestions/QuestionStepValidation";
import NumberInputStep from "./InputTypeNumber/NumberInputStep";
import PhoneNumberInputStep from "./InputTypeNumber/PhoneNumberInputStep";
import MultiChoiceStepLink from "./UploadFileType/UploadFileTypeStep";
import EmailTypeStep from "./EmailTypeQuestion/EmailTypeStep";
import StateSelectionQuestion from "./StateSelectionQuestion/StateSelectionQuestion";
import "../css/bootstrap.min.css";
import "../css/bootstrap.css";
import "../css/style.css";
import { useMedia } from 'react-use';
import ProgressBar from "@ramonak/react-progress-bar";
import useFetchQuestion from "../api/FetchQuestions";
import useCreateUserAssessmentResponse from "../api/CreateUserAccessment";
import useCreateUserQuestionResponse from "../api/CreateUserQuestionResponse";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import MobileVerification from "./Verification/PhoneNumberVerification";
import EmailVerification from "./Verification/EmailVerification";
import LeftSideBar from "./LeftSideBar";
import AdvertiseMentModal from "../helpers/AdvertisementModal";
import { setIsConsentForm, resetState, setClientConfiguration, setFormConfiguration } from '../redux/student/studentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { dynamicData, imageQuestion } from "../helpers/data";
import NameInputStep from "./InputTypeText/NameTypeQuestion";
import DobInputType from "./DobInputQuestion/DobInputQuestion";
import ThankYouPage from "./ThankYouPage/ThankYou";
import FlexibleInputStep from "./FlexibleInputStep/FlexibleInputStep";
import IndianStateSelectionQuestion from "./StateSelectionQuestion/IndianStateSelectionQuestion";
import TestTimer from "./TestTimer/countDown";
import { current } from "@reduxjs/toolkit";
import { setQuestionNumber } from "../redux/student/studentSlice";
import TestTermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import { TERMS_AND_CONDITIONS, ASKIITIAN_TERMS_AND_CONDITIONS } from "../utils/constants/constants";
import ImageQuestionStep from "./ImageTypeQuestion/QuestionImage";
import { updateAnswerIndex, resetAnswerStatus } from "../redux/student/studentSlice";
import updateUserResponse from "../api/useUpdateUserResponse";
import Lottie from 'lottie-react';
import animationData from '../assets/Animation/loading_animation.json';
import RangeTypeQuestion from "./RangeTypeQuestion/RangeTypeQuestion";
import OrderTypeQuestion from "./OrderTypeQuestion/OrderTypeQuestion";
import { useEvent } from 'react-use';
import PerQuestionTimer from "./TestTimer/PerQuestionTimer";
import useCreateSchoolCode from "../api/CreateSchoolCode";
import useCreateStudentCode from "../api/CreateStudentCode";
import useFetchClientConfig from '../api/FetchClientConfig';
import useFetchFormConfig from "../api/FetchFormConfig";
import useMultipleQuestionResponses from "../api/useMultipleQuestionResponses";
import TestSubmissionPage from "./TestSubmission/TestSubmission";
import './DataTables.css';
import useFetchFlowBlocks from "../api/FetchFlowBlocks";
import useFetchStudentDataEmail from "../api/FetchStudentDetailsEmail";
import useUpdateUserQuestionResponse from "../api/UpdateUserQuestionResponse";
import { Helmet } from 'react-helmet';

const MultiStepForm = () => {
    const [pendingApiData, setPendingApiData] = useState( null );
    const [pendingApiDataMultiChoice, setPendingApiDataMultiChoice] = useState( null );
    const [pendingApiDataDropDown, setPendingApiDataDropDown] = useState( null );
    const [pendingApiTextInput, setPendingApiTextInput] = useState( null );
    const [pendingApiNumberInput, setPendingApiNumberInput] = useState( null );
    const [pendingApiUploadFile, setPendingApiUploadFile] = useState( null );
    const [pendingApiEmailInput, setPendingApiEmailInput] = useState( null );
    const [pendingApiVerification, setPendingApiVerification] = useState( null );
    const [pendingStateQuestion, setPendingStateQuestion] = useState( null );
    const [pendingOrderType, setPendingOrderType] = useState( null );
    const [pendingApiRangeType, setPendingApiRangeType] = useState( null );
    const [showProgressBar, setShowProgressBar] = useState( true )

    const dispatch = useDispatch();
    const questionNumber = useSelector( state => state.currentQuestion.questionNumber );
    const isConsentForm = useSelector( state => state.consentForm.isConsentForm )
    const isFormSubmitted = useSelector( state => state.formSubmitted.isFormSubmitted );
    const isLoadingScreen = useSelector( state => state.loadingScreen.isLoadingScreen );
    const formConfig = useSelector( state => state.formConfiguration );

    const clientConfig = useSelector( state => state.clientConfiguration );

    const isRequiredQuestionsAttempted = useSelector( state => state.requiredQuestionsAttempted.isRequiredQuestionsAttempted )

    const batchSize = 5;

    const [isAllAnswersValid, setIsAllAnswersValid] = useState( false );

    const [isModalOpen, setIsModalOpen] = useState( false );
    const [userType, setUserType] = useState( '' );

    const [dynamicQuestions, setDynamicQuestions] = useState( [] );

    const [textAnswers, setTextAnswers] = useState( [] );

    //const [currentDynamicStep, setCurrentDynamicStep] = useState( 0 );
    const { client_name, grade, user_type, test_type, testId } = useParams();

    const clientConfiguration = sessionStorage.getItem( 'clientConfiguration' );
    const formConfiguration = sessionStorage.getItem( 'formConfiguration' );

    const { response: updateUserResponseData, isLoading: updateUserResponseLoading, error: updateUserResponseError, updateResponse } = updateUserResponse();
    const { updateQuestionResponse, multipleQuestionResponses } = useMultipleQuestionResponses( batchSize );

    const { response: studentData, isLoading: studentDataLoading, error: studentDataError, FetchStudentDataEmail } = useFetchStudentDataEmail();

    const { response: flowBlocks, isLoading: flowLoading, FetchFlowBlocks } = useFetchFlowBlocks( testId );

    const { response: clientConfigData, isLoading, fetchClientConfig } = useFetchClientConfig();
    const { response: formConfigData, isLoading: formConfigLoading, fetchFormConfig } = useFetchFormConfig();

    useEffect( () => {
        if ( client_name && !clientConfiguration ) {
            fetchClientConfig( client_name );
        }
    }, [client_name] )

    useEffect( () => {
        if ( client_name && !formConfiguration ) {
            fetchFormConfig( client_name, test_type, testId );
        }
    }, [client_name] )

    useEffect( () => {
        if ( clientConfigData && clientConfigData?.data ) {
            dispatch( setClientConfiguration( clientConfigData.data ) );
            sessionStorage.setItem( 'clientConfiguration', JSON.stringify( clientConfigData.data ) );

            const timeout = setTimeout( () => {
                sessionStorage.removeItem( 'clientConfiguration' );
                fetchClientConfig( client_name );
            }, 10 * 60 * 1000 );
            return () => clearTimeout( timeout );
        }
    }, [clientConfigData, client_name] );

    useEffect( () => {
        if ( formConfigData && formConfigData?.data?.form_config ) {
            dispatch( setFormConfiguration( formConfigData?.data ) );
            sessionStorage.setItem( 'formConfiguration', JSON.stringify( formConfigData?.data ) );

            const timeout = setTimeout( () => {
                sessionStorage.removeItem( 'formConfiguration' );
                fetchFormConfig( client_name, test_type, testId );
            }, 60 * 60 * 1000 );
            return () => clearTimeout( timeout );
        }
    }, [formConfigData, formConfigLoading] )

    const data = formConfigData?.data ? formConfigData?.data?.form_data : formConfig?.form_data;
    const form_config_data =
        formConfigData?.data ? formConfigData?.data?.form_config : formConfig?.form_config;

    const form_type = data?.form_type;
    const template_id = data?.pdf_template_name;
    const form_id = form_config_data?.form_id;

    const [addedFollowUps, setAddedFollowUps] = useState( [] );
    const [email, setEmail] = useState( '' );
    const [name, setName] = useState( '' );
    const [phoneNumber, setPhoneNumber] = useState( '' );
    const [showAdvertisement, setShowAdvertisement] = useState( false );

    const isTest = window.location.href.includes( 'test' )

    const student_grade_local_storage = localStorage.getItem( 'student_grade' );
    //console.log( "questionNumberCircle2", questionNumber );


    const resetAdvertisement = () => {
        setShowAdvertisement( false );
    };

    const handleProceedToQuiz = () => {
        dispatch( setIsConsentForm( false ) );
    };

    const navigate = useNavigate();
    const location = useLocation();

    const params = new URLSearchParams( location.search );
    const utmSource = params.get( 'utm_source' );
    const utmMedium = params.get( 'utm_medium' );
    const utmCampaign = params.get( 'utm_campaign' );
    const userEmail = params.get( 'user_email' );
    const userName = params.get( 'user_name' );

    useEffect( () => {
        if ( userEmail ) {
            FetchStudentDataEmail( userEmail, client_name )
        }
    }, [userEmail, userName] )

    const [workFlowList, setWorkFlowList] = useState( [] );
    const [currentComponent, setCurrentComponent] = useState( 0 );
    const [currentFlowName, setCurrentFlowName] = useState( "" )

    useEffect( () => {
        let workflowList = [];
        if ( flowBlocks ) {
            workflowList = flowBlocks.map( item => item.block_type );
        }
        setWorkFlowList( workflowList );
    }, [flowBlocks] );

    useEffect( () => {
        if ( workFlowList?.length > 0 ) {
            setCurrentFlowName( workFlowList[currentComponent] );
        }
    }, [currentComponent, workFlowList] );

    useEffect( () => {
        if ( clientConfigData ) {
            document.title = clientConfigData?.data?.tag_line;
            document.querySelector( 'meta[name="description"]' ).setAttribute( "content", clientConfigData?.data?.description );
        }
    }, [clientConfigData] );

    const terms_and_conditions = dynamicData[form_config_data?.client_id]?.terms_and_conditions || "";
    //const student_route = dynamicData.default.student_route;
    //const parent_route = dynamicData.default.parent_route;
    //const college_route = dynamicData.default.college_route;
    //const working_professional_route = dynamicData.default.working_professional_route;
    //const test_verification_mobile = form_config_data?.test_verification_mobile || '';
    //const proceedToTest = form_config_data?.proceed_to_test || '';

    //const isCouponCodeVerification = form_config_data?.coupon_code_verification || '';
    const viewReport = form_config_data?.view_report || '';

    //const is_mobile_verification = form_config_data?.is_mobile_verification || '';
    //const is_email_verification = form_config_data?.is_email_verification || '';
    //const is_Loading_Screen = form_config_data?.is_loading_screen || '';
    const showTimer = data?.form_timer || '';
    const perQuestionTimer = data?.per_question_timer || '';
    const isPagination = form_config_data?.is_pagination || '';
    const isQuestionNavigation =
        ( formConfigData?.is_question_navigation || '' ) &&
        window.location.href.includes( 'test' );

    const testTimeInSeconds = data?.overall_timer || '';

    const isLeftSidebarVisible = form_config_data?.is_left_sidebar_visible || ''

    const school_registration_route = false;
    const student_registration_route = true;
    //useEffect( () => {
    //  if ( id === student_route ) {
    //    dispatch( setIsStudent( true ) );
    //    dispatch( setIsParent( false ) );
    //  } else if ( id === parent_route ) {
    //    dispatch( setIsParent( true ) );
    //    dispatch( setIsStudent( false ) );
    //  } else if ( id === school_registration_route ) {
    //    dispatch( setIsSchoolRegistration( true ) );
    //    dispatch( setIsStudentRegistration( false ) );
    //  } else if ( id === student_registration_route ) {
    //    dispatch( setIsStudentRegistration( true ) );
    //    dispatch( setIsSchoolRegistration( false ) );
    //  } else {
    //    console.log( 'Don\'t try to look here' )
    //  }
    //}, [id] )

    useEffect( () => {
        let productCode = "";
        if ( user_type === 'college' ) {
            productCode = "COLLEGE_SURVEY";
        } else if ( user_type === 'workingprofessional' ) {
            productCode = "PROFESSIONAL_SURVEY";
        }
        localStorage.setItem( 'product_code', productCode );
    }, [user_type] )

    //const { data, isLoading, isError } = useFetchQuestion( form_id );

    const { createSchoolCode, response: createSchoolResponse, error: schoolError, isLoading: creatingSchool } = useCreateSchoolCode();
    const { createStudentCode, response: createStudentResponse, error: studentError, isLoading: creatingStudent } = useCreateStudentCode();

    const [userAssessmentResponseID, setUserAssessmentResponseID] = useState(
        localStorage.getItem( 'userAssessmentResponseID' )
    );

    useEffect( () => {
        if ( data?.questions ) {
            setDynamicQuestions( [...data.questions] );
        }
    }, [data] );

    useEvent( 'keydown', ( e ) => {
        if ( e.key === 'Tab' ) {
            e.preventDefault();
        }
    } );


    const [validations, setValidations] = useState( [] );

    useEffect( () => {
        if ( dynamicQuestions?.length && isPagination ) {
            const initialBatchSize = Math.min( batchSize, dynamicQuestions?.length );
            setValidations( Array.from( { length: initialBatchSize }, () => ( {} ) ) )
        }
    }, [dynamicQuestions?.length] );

    //const userId = localStorage.getItem( 'user_id' ) || localStorage.getItem( 'student_roll_number' ) || 0;

    const [userId, setUserId] = useState();

    const is_completed = localStorage.getItem( 'is_completed' ) || 1;

    const { response, error, CreateUserAssessmentResponse } = useCreateUserAssessmentResponse()

    useEffect( () => {
        if ( studentData && studentData?.student_code && form_type === "test" ) {
            const rollNumber = studentData?.student_code;
            localStorage.setItem( 'user_id', rollNumber );
            setUserId( rollNumber );
        } else if ( form_type === "survey" ) {
            setUserId( 0 );
        } else if ( !userEmail && !userName && form_type === "test" ) {
            setUserId( 0 );
        } else if ( studentData && !studentData?.student_code ) {
            setUserId( 0 )
        }
    }, [studentData, response, data] )

    const createAccessmentId = () => {
        if ( data && userId !== undefined ) {
            dispatch( setQuestionNumber( 0 ) );
            dispatch( resetState() );
            CreateUserAssessmentResponse( userId, form_id, is_completed, testId, utmSource, utmMedium, utmCampaign );
        }
    }

    useEffect( () => {
        createAccessmentId();
    }, [data, userId] )

    useEffect( () => {
        if ( response )
            if ( userAssessmentResponseID === null && form_type === "survey" ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
            } else if ( userAssessmentResponseID === null && form_type === "test" ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
            } else if ( userAssessmentResponseID !== null && form_type === "survey" ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
            } else if ( userAssessmentResponseID !== null && form_type === "test" ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
            }
        setUserAssessmentResponseID( localStorage.getItem( 'userAssessmentResponseID' ) )
    }, [response] )

    useEffect( () => {
        if ( userEmail && userName && response && !studentData?.student_code ) {
            createStudentCode( userName, "", userEmail, client_name, response.id, "", form_config_data?.client_id, "" )
        }
    }, [response, studentData] );

    useEffect( () => {
        if ( userAssessmentResponseID !== null ) {
            const storedTextAnswers = localStorage.getItem( `textAnswers_${ form_id }_${ userAssessmentResponseID }` );
            if ( storedTextAnswers ) {
                setTextAnswers( JSON.parse( storedTextAnswers ) );
            } else if ( data?.questions ) {
                setTextAnswers( Array( data.questions.length ).fill( '' ) );
            }
        }
    }, [data, form_id, userAssessmentResponseID] );

    useEffect( () => {
        if ( !userAssessmentResponseID ) {
            // No ID in localStorage, make API call
            if ( response?.id && localStorage.getItem( 'userAssessmentResponseID' ) === null ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
                setUserAssessmentResponseID( response.id );
            }
        }
        localStorage.setItem( 'form_id', form_id );
        localStorage.setItem( 'clientId', form_config_data?.client_id );
    }, [response, form_id, userAssessmentResponseID] );

    // Function to get the stored currentDynamicStep value from localStorage
    const getStoredCurrentDynamicStep = () => {
        const storedStep = localStorage.getItem( `currentDynamicStep_${ form_id }` );
        return storedStep ? parseInt( storedStep, 10 ) : 0;
    };

    //const [currentDynamicStep, setCurrentDynamicStep] = useState( getStoredCurrentDynamicStep() );
    const [currentDynamicStep, setCurrentDynamicStep] = useState( 0 );

    useEffect( () => {
        setCurrentDynamicStep( questionNumber );
    }, [questionNumber] );


    useEffect( () => {
        // Update the stored currentDynamicStep whenever it changes
        dispatch( setQuestionNumber( currentDynamicStep ) );
    }, [currentDynamicStep] );

    useEffect( () => {
        // Check if textAnswers is non-empty before updating local storage
        if ( textAnswers.length > 0 && userAssessmentResponseID !== null ) {
            localStorage.setItem( `textAnswers_${ form_id }_${ userAssessmentResponseID }`, JSON.stringify( textAnswers ) );
        }
    }, [textAnswers, form_id, userAssessmentResponseID] );

    const [answerResponseid, setAnswerResponseid] = useState( [] );

    const { createUserQuestionResponse, response: answerResponse } = useCreateUserQuestionResponse();

    const { updateUserQuestionResponse } = useUpdateUserQuestionResponse();

    const [dateOfbirth, setDateOfbirth] = useState( Array( 10 ).fill( '' ) );

    useEffect( () => {
        if ( answerResponse ) {
            let newAnswerResponses = [...answerResponseid];
            newAnswerResponses[currentDynamicStep - 1] = answerResponse.id;
            setAnswerResponseid( newAnswerResponses );
        }
    }, [answerResponse] )

    const handleSingleChoiceSelect = ( option, currentDynamicStep, index ) => {

        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = option;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;
        const selectedOptionId = option?.id;


        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = "";
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [selectedOptionId].map( optionId => ( { option_id: optionId } ) );
            } );
        }

        setPendingApiData( {
            currentQuestionID,
            userAssessmentResponseID,
            selectedOptionId: [selectedOptionId]
        } );

        const updatedDynamicQuestions = [...dynamicQuestions];
        const prevFollowUpIndex = addedFollowUps[currentDynamicStep];

        // If there was a previous follow-up question, remove it
        if ( prevFollowUpIndex !== undefined ) {
            updatedDynamicQuestions.splice( prevFollowUpIndex, 1 );
            setAddedFollowUps( { ...addedFollowUps, [currentDynamicStep]: undefined } );
        }

        // If the new option has a follow-up question, add it
        if ( option?.follow_up_question ) {
            updatedDynamicQuestions.splice( currentDynamicStep + 1, 0, option.follow_up_question );
            setAddedFollowUps( { ...addedFollowUps, [currentDynamicStep]: currentDynamicStep + 1 } );

        }
        setDynamicQuestions( updatedDynamicQuestions );
    };

    const handlesingleChoiceClearAll = ( option ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = null; // Clear the selected option
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        // Send an empty array to the API to indicate that nothing is selected
        setPendingApiData( {
            currentQuestionID,
            userAssessmentResponseID,
            selectedOptionId: [],
        } );

        const selectedOptionId = option?.id;

        if ( selectedOptionId?.length > 0 ) {
            // Dispatch updateAnswerIndex if selectedOptionId is not empty
            dispatch( updateAnswerIndex( currentDynamicStep ) );
        } else {
            // Dispatch resetAnswerStatus if selectedOptionId is empty
            dispatch( resetAnswerStatus( currentDynamicStep ) );
        }

        createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedOptionId );
        setPendingApiData( null ); // Clear pendingApiData
    };

    const handleSingleChoiceNext = () => {
        // Make API call using pendingApiData if it exists
        if ( pendingApiData ) {
            const { currentQuestionID, userAssessmentResponseID, selectedOptionId } = pendingApiData;

            if ( selectedOptionId.length > 0 ) {
                // Dispatch updateAnswerIndex if selectedOptionId is not empty
                dispatch( updateAnswerIndex( currentDynamicStep ) );
            } else {
                // Dispatch resetAnswerStatus if selectedOptionId is empty
                dispatch( resetAnswerStatus( currentDynamicStep ) );
            }

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( selectedOptionId, '', answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedOptionId );
            }
            setPendingApiData( null ); // Clear pendingApiData
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const handleMultipleChoiceSelect = ( selectedOptionIds, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = selectedOptionIds;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = "";
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = selectedOptionIds.map( optionId => ( { option_id: optionId } ) );
            } );
        }

        // Populate pendingApiDataMultiChoice
        setPendingApiDataMultiChoice( {
            currentQuestionID,
            userAssessmentResponseID,
            selectedOptionIds,
        } );



        // This will be the updated list of dynamic questions after insertion/removal of follow-ups
        let updatedDynamicQuestions = [...dynamicQuestions];

        // Identify the follow-up questions that are currently in the dynamicQuestions list
        const currentFollowUps = updatedDynamicQuestions.slice( currentDynamicStep + 1 )
            .filter( q => q.isFollowUp ); // Assuming isFollowUp is a property you set when adding follow-ups

        // Remove all current follow-up questions related to the current step
        updatedDynamicQuestions = updatedDynamicQuestions.filter( q => !q.isFollowUp || !currentFollowUps.includes( q ) );

        // Now, determine which follow-up questions to add based on the newly selected options
        selectedOptionIds.forEach( id => {
            const option = dynamicQuestions[currentDynamicStep].options.find( o => o.id === id );
            if ( option && option.follow_up_question ) {
                // Mark the question as a follow-up for easier identification
                const followUpQuestion = { ...option.follow_up_question, isFollowUp: true };
                updatedDynamicQuestions.splice( currentDynamicStep + 1, 0, followUpQuestion );
            }
        } );

        // Update the dynamicQuestions state with the new questions list
        setDynamicQuestions( updatedDynamicQuestions );
    };

    const handleMultiChoiceNext = () => {
        if ( pendingApiDataMultiChoice ) {
            const { currentQuestionID, userAssessmentResponseID, selectedOptionIds } = pendingApiDataMultiChoice;
            if ( selectedOptionIds.length > 0 ) {
                // Dispatch updateAnswerIndex if selectedOptionId is not empty
                dispatch( updateAnswerIndex( currentDynamicStep ) );
            } else if ( selectedOptionIds.length === 0 ) {
                // Dispatch resetAnswerStatus if selectedOptionId is empty
                dispatch( resetAnswerStatus( currentDynamicStep ) );
            }

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( selectedOptionIds, '', answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedOptionIds );
            }
            setPendingApiDataMultiChoice( null );
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const handleDropdownSelect = ( selectedValues, currentDynamicStep, index ) => {
        const normalizedSelectedValues = Array.isArray( selectedValues ) ? selectedValues : [selectedValues];

        const selectedIds = selectedValues.filter( option => typeof option === 'number' );
        const selectedTexts = selectedValues.filter( option => typeof option === 'string' );
        const normalizedId = Array.isArray( selectedTexts ) ? selectedTexts[0] : selectedTexts;

        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = selectedValues;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;


        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = normalizedId;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = selectedIds.map( optionId => ( { option_id: optionId } ) );
            } );
        }

        setPendingApiDataDropDown( {
            currentQuestionID,
            userAssessmentResponseID,
            selectedValues: selectedIds,
            textResponse: normalizedId,
        } );

        // This will be the updated list of dynamic questions after insertion/removal of follow-ups
        let updatedDynamicQuestions = [...dynamicQuestions];

        // Identify the follow-up questions that are currently in the dynamicQuestions list
        const currentFollowUps = updatedDynamicQuestions.slice( currentDynamicStep + 1 )
            .filter( q => q.isFollowUp ); // Assuming isFollowUp is a property you set when adding follow-ups

        // Remove all current follow-up questions related to the current step
        updatedDynamicQuestions = updatedDynamicQuestions.filter( q => !q.isFollowUp || !currentFollowUps.includes( q ) );

        // Now, determine which follow-up questions to add based on the newly selected options
        normalizedSelectedValues.forEach( id => {
            const option = dynamicQuestions[currentDynamicStep].options.find( o => o.id === id );
            if ( option && option.follow_up_question ) {
                // Mark the question as a follow-up for easier identification
                const followUpQuestion = { ...option.follow_up_question, isFollowUp: true };
                updatedDynamicQuestions.splice( currentDynamicStep + 1, 0, followUpQuestion );
            }
        } );

        // Update the dynamicQuestions state with the new questions list
        setDynamicQuestions( updatedDynamicQuestions );
    };

    const handleCourseSelect = ( answer, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        setPendingApiDataDropDown( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: answer
        } );
    };

    const handleDropDownNext = () => {
        // Make API call using pendingApiDataMultiChoice if it exists
        if ( pendingApiDataDropDown ) {
            const { currentQuestionID, userAssessmentResponseID, selectedValues, textResponse } = pendingApiDataDropDown;

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( selectedValues, textResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedValues, textResponse );
            }
            setPendingApiDataDropDown( null );
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const handleTextAnswerChange = ( answer, currentDynamicStep, index ) => {
        // Update the local state for text answers
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        // Populate pendingApiTextInput
        setPendingApiTextInput( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: answer
        } );
    };

    const handleTextNext = () => {
        // Make API call using pendingApiTextInput if it exists
        if ( pendingApiTextInput ) {
            const { currentQuestionID, userAssessmentResponseID, textResponse } = pendingApiTextInput;
            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], textResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
            }
            setPendingApiTextInput( null );  // Clear pendingApiTextInput
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const handleNameAnswerChange = ( answer, currentDynamicStep, index ) => {
        // Update the local state for text answers
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        // Populate pendingApiTextInput
        setPendingApiTextInput( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: answer
        } );
    };

    const handleNameNext = () => {
        // Make API call using pendingApiTextInput if it exists
        if ( pendingApiTextInput ) {
            const { currentQuestionID, userAssessmentResponseID, textResponse } = pendingApiTextInput;

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], textResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
            }
            localStorage.setItem( 'user_name', textResponse );
            localStorage.setItem( "studentName", textResponse )
            setPendingApiTextInput( null );  // Clear pendingApiTextInput
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };


    const handleNumberTextAnswerChange = ( answer, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;
        setPhoneNumber( answer?.slice( 3 ) );

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }
        // Populate pendingApiNumberInput
        setPendingApiNumberInput( {
            currentQuestionID,
            userAssessmentResponseID,
            numberTextResponse: answer
        } );
    };

    const handleNumberTextNext = () => {
        // Make API call using pendingApiNumberInput if it exists
        if ( pendingApiNumberInput ) {
            const { currentQuestionID, userAssessmentResponseID, numberTextResponse } = pendingApiNumberInput;
            localStorage.setItem( 'phone_number', numberTextResponse );
            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], numberTextResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], numberTextResponse );
            }
            setPendingApiNumberInput( null );  // Clear pendingApiNumberInput
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };


    const handleEmailType = ( answer, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        setPendingApiEmailInput( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: answer
        } );
    };

    const handleEmailTypeNext = () => {
        if ( pendingApiEmailInput ) {
            const { currentQuestionID, userAssessmentResponseID, textResponse } = pendingApiEmailInput;

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], textResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
            }
            setEmail( textResponse )
            setPendingApiEmailInput( null );
            localStorage.setItem( 'user_email', textResponse );
            //setEmail( textResponse )
            //console.log( email );
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    }

    const handleBack = () => {
        if ( currentDynamicStep === 0 ) return null;
        setCurrentDynamicStep( currentDynamicStep - 1 );
    };

    const handleUploadFileChange = ( option ) => {

        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = option;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;
        const selectedOptionId = option?.id;

        // Store this data for later submission to the API
        setPendingApiUploadFile( {
            currentQuestionID,
            userAssessmentResponseID,
            selectedOptionId: [selectedOptionId]
        } );
    };


    const handleNextUpload = ( data ) => {
        // Logic to send data to API
        if ( pendingApiUploadFile ) {
            const { currentQuestionID, userAssessmentResponseID, selectedOptionId } = pendingApiUploadFile;
            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( selectedOptionId, data, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedOptionId, data );
            }
            setPendingApiUploadFile( null );
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const handleVerificationType = ( answer, stepIndex ) => {
        if ( phoneNumber ) {
            setTextAnswers( phoneNumber );
        } else {
            let newTextAnswers = [...textAnswers];
            newTextAnswers[stepIndex] = answer;
            setTextAnswers( newTextAnswers );
        }

        const currentQuestionID = 4;

        setPendingApiVerification( {
            currentQuestionID,
            userAssessmentResponseID,
            numberTextResponse: answer
        } );
    };

    const handleVerificationTypeNext = () => {
        if ( pendingApiVerification ) {
            const { currentQuestionID, userAssessmentResponseID, numberTextResponse } = pendingApiVerification;
            createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], numberTextResponse );
            setEmail( numberTextResponse )
            setPhoneNumber( numberTextResponse )
            setPendingApiVerification( null );
        }
    };

    const handleStateAnswerChange = ( answer, currentDynamicStep, index ) => {
        // Update the local state for text answers
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        // Populate pendingApiTextInput
        setPendingStateQuestion( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: answer
        } );
    };

    const handleStateNext = () => {
        // Make API call using pendingApiTextInput if it exists
        if ( pendingStateQuestion ) {
            const { currentQuestionID, userAssessmentResponseID, textResponse } = pendingStateQuestion;
            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], textResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
            }
            setPendingStateQuestion( null );  // Clear pendingApiTextInput
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };


    const handleOrderTypeQuestion = ( optionsOrder, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = optionsOrder;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = "";
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = optionsOrder;
            } );
        }

        // Populate pendingApiDataMultiChoice
        setPendingOrderType( {
            currentQuestionID,
            userAssessmentResponseID,
            selectedOptionId: optionsOrder,
        } );
    }

    const handleOrderTypeNext = () => {
        if ( pendingOrderType ) {
            const { currentQuestionID, userAssessmentResponseID, selectedOptionId } = pendingOrderType;

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( selectedOptionId, '', answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedOptionId );
            }

        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
        setPendingOrderType( null );
    };

    const handleRangeTypeQuestion = ( range, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = range;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = range;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        setPendingApiRangeType( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: range
        } );
    }

    const handleRangeTypeNext = () => {
        // Make API call using pendingApiTextInput if it exists
        if ( pendingApiRangeType ) {
            const { currentQuestionID, userAssessmentResponseID, textResponse } = pendingApiRangeType;

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], textResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
            }
            setPendingApiTextInput( null );
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const totalSteps = dynamicQuestions.length;
    const completedProgress = Math.round( ( currentDynamicStep / dynamicQuestions.length ) * 100 );

    const renderQuestionComponent = ( currentDynamicStep, index ) => {

        const questionToRender = dynamicQuestions[currentDynamicStep];
        switch ( questionToRender?.input_type ) {
            case "single_choice_question":
                return (
                    <QuestionStep
                        question={ questionToRender }
                        selected={ textAnswers[currentDynamicStep] }
                        onSelect={ ( option ) => handleSingleChoiceSelect( option, currentDynamicStep, index ) }
                        onNext={ handleSingleChoiceNext }
                        onBack={ handleBack }
                        onClearAll={ handlesingleChoiceClearAll }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "multiple_choice_question":
                return (
                    <MultiChoiceStep
                        question={ questionToRender }
                        selectedOptionsFromParent={ textAnswers[currentDynamicStep] }
                        onSelect={ ( options ) => handleMultipleChoiceSelect( options, currentDynamicStep, index ) }
                        onNext={ handleMultiChoiceNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "drop_down_multiselect_question":
                return (
                    <DropDownStepMultipleSelect
                        question={ questionToRender }
                        selectedOptionsFromParent={ textAnswers[currentDynamicStep] }
                        onSelect={ ( selectedOptions ) => handleDropdownSelect( selectedOptions, currentDynamicStep, index ) }
                        onNext={ handleDropDownNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "drop_down_singleselect_question":
                return (
                    <DropDownStepSingleSelect
                        question={ questionToRender }
                        selectedOptionsFromParent={ textAnswers[currentDynamicStep] }
                        onSelect={ ( selectedOptions ) => handleDropdownSelect( selectedOptions, currentDynamicStep, index ) }
                        onNext={ handleDropDownNext }
                        onBack={ handleBack }
                        courseName={ ( answer ) => handleCourseSelect( answer, currentDynamicStep, index ) }
                        selectedCourseName={ textAnswers[currentDynamicStep] }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "text_area_question":
                return (
                    <TextInputStep
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleTextAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleTextNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "number_input_question":
                return (
                    <NumberInputStep
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleNumberTextAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleNumberTextNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "upload_type_question":
                return (
                    <MultiChoiceStepLink
                        question={ questionToRender }
                        selected={ textAnswers[currentDynamicStep] }
                        onSelect={ handleUploadFileChange }
                        onNextUpload={ handleNextUpload }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                    />
                );
            case "email_type_question":
                return (
                    <EmailTypeStep
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleEmailType( answer, currentDynamicStep, index ) }
                        onNext={ handleEmailTypeNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                )
            case "phone_number_input_question":
                return (
                    <PhoneNumberInputStep
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleNumberTextAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleNumberTextNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                )
            case "states_selection_question":
                return (
                    <StateSelectionQuestion
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleStateAnswerChange( answer, currentDynamicStep ) }
                        onNext={ handleStateNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                    />
                )
            case "name_input_question":
                return (
                    <NameInputStep
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleNameAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleNameNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "date_input_type":
                return (
                    <DobInputType
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleTextAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleTextNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "number_and_text_input_type":
                return (
                    <FlexibleInputStep
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleTextAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleTextNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "indian_state_selection_type":
                return (
                    <IndianStateSelectionQuestion
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer, ) => handleStateAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleStateNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "nudge":
                return (
                    <AdvertiseMentModal
                        onNext={ () => {
                            setShowProgressBar( true )
                            setCurrentDynamicStep( currentDynamicStep + 1 )
                        } }
                        resetAdvertisement={ resetAdvertisement }
                        nudge={ dynamicQuestions[currentDynamicStep]?.nudge }
                        onLoad={ () => setShowProgressBar( false ) }
                        onBack={ () => {
                            setShowProgressBar( true );
                            setCurrentDynamicStep( currentDynamicStep - 1 )
                        } }
                    />
                );
            case "ranking_input_type":
                return (
                    <OrderTypeQuestion
                        question={ questionToRender }
                        selectedOptionsFromParent={ textAnswers[currentDynamicStep] }
                        onSelect={ ( optionsOrder ) => handleOrderTypeQuestion( optionsOrder, currentDynamicStep, index ) }
                        onNext={ handleOrderTypeNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "range_type_question":
                return (
                    <RangeTypeQuestion
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( range ) => handleRangeTypeQuestion( range, currentDynamicStep, index ) }
                        onNext={ handleRangeTypeNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            default:
                return (
                    <TestSubmissionPage />
                );
        }
    };

    const renderQuestionBatch = ( startIndex ) => {
        return dynamicQuestions
            .slice( startIndex, startIndex + batchSize )
            .map( ( question, index ) => {
                return renderQuestionComponent( startIndex + index, index );
            } );
    };

    const handleResetValidations = ( size = batchSize ) => {
        setValidations( Array( size ).fill( {} ) ); // Initialize validations based on the actual size passed
    };


    useEffect( () => {
        const allTrue = validations.length > 0 && validations.every( value => value === true );
        setIsAllAnswersValid( allTrue );
    }, [validations] );

    const handleNextBatch = ( startIndex ) => {
        const questionCount = dynamicQuestions.length - startIndex;
        const currentBatchSize = Math.min( questionCount, batchSize );

        if ( isAllAnswersValid ) {
            multipleQuestionResponses();
            setCurrentDynamicStep( startIndex );
            if ( startIndex >= dynamicQuestions.length ) {
                return
            } else {
                handleResetValidations( currentBatchSize ); // Only reset with a positive size
            }
        } else {
            notification.error( {
                message: 'Please attempt all the questions',
            } );
        }
    };

    const renderQuestions = ( startIndex ) => {
        return (
            <>
                { isPagination ? (
                    <>
                        { renderQuestionBatch( startIndex ) }
                        <button
                            className={ `text-white text-center rounded py-2 px-4 mt-4 ${ !isAllAnswersValid ? 'bg-blue-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600' }` }
                            onClick={ () => handleNextBatch( startIndex + batchSize ) }
                        >
                            Next
                        </button>
                    </>
                ) : (
                    renderQuestionComponent( startIndex )
                ) }
            </>
        );
    };

    //const handleFinishAttempt = () => {
    //  if ( isRequiredQuestionsAttempted ) {
    //    updateResponse( { id: userAssessmentResponseID, is_completed: 3 } )
    //    dispatch( setIsLoadingScreen( true ) );
    //    localStorage.setItem( 'isLoadingScreen', true )
    //  } else {
    //    notification.error( {
    //      message: 'Unable to submit!',
    //      description: 'Please attempt all the required questions to submit the test.',
    //    } );
    //  }
    //}

    //const handleFinishAttempt = () => {
    //  updateResponse( { id: userAssessmentResponseID, is_completed: 3 } )
    //  dispatch( setIsLoadingScreen( true ) );
    //  localStorage.setItem( 'isLoadingScreen', true )
    //  setIsModalOpen( false );
    //}

    useEffect( () => {
        if ( currentDynamicStep > 0 )
            if ( data?.questions && currentDynamicStep + 1 > dynamicQuestions.length ) {
                setIsModalOpen( true );
            } else {
                setIsModalOpen( false );
            }
    }, [currentDynamicStep] )


    const handleOk = () => {
        if ( isTest ) {
            updateResponse( { id: userAssessmentResponseID, is_completed: 3, clientName: client_name } )
            setCurrentComponent( currentComponent + 1 );
            setIsModalOpen( false );
        } else {
            const user_email = localStorage.getItem( 'user_email' ) || "user_email";
            const userAddress = localStorage.getItem( 'userAddress' ) || "user_address";
            const contactPersonPhone = localStorage.getItem( 'contact_person_phone' ) || "contact_person_phone";
            const contactPersonName = localStorage.getItem( 'contact_person_name' ) || "contact_person_name";
            const schoolName = localStorage.getItem( 'userSchoolName' ) || "school_name";

            const studentName = localStorage.getItem( 'studentName' ) || "student_name";
            const studentGrade = localStorage.getItem( 'studentGrade' ) || "student_grade";
            const studentEmail = localStorage.getItem( 'studentEmail' ) || "student_email";
            const responseId = localStorage.getItem( 'userAssessmentResponseID' ) || "1234";
            const studentDateOfBirth = localStorage.getItem( 'studentDateOfBirth' ) || "1970-01-01";
            const examinationMode = localStorage.getItem( 'examination_mode' ) || "online";

            const studentPh = localStorage.getItem( 'phone_number' ) || "";

            if ( school_registration_route ) {
                createSchoolCode( schoolName, userAddress, contactPersonName, contactPersonPhone, user_email, examinationMode, responseId, client_name );
            } else if ( student_registration_route ) {
                createStudentCode( studentName, studentGrade, user_email, client_name, responseId, studentDateOfBirth, form_config_data?.client_id, studentPh )
            } else {
                return;
            }
        }
    };

    useEffect( () => {
        if ( createSchoolResponse && !schoolError ) {
            localStorage.clear();
        } else if ( createStudentResponse && !studentError ) {
            const studentRollNumber = createStudentResponse?.student_code;
            localStorage.setItem( 'student_roll_number', studentRollNumber );
            updateResponse( { id: userAssessmentResponseID, is_completed: 3, clientName: client_name } )
        }
    }, [createSchoolResponse, createStudentResponse] )

    useEffect( () => {
        if ( updateUserResponseData ) {
            if ( viewReport ) {
                Object.keys( localStorage ).forEach( ( key ) => {
                    if ( key !== 'student_roll_number' && key !== 'studentName' && key !== 'userAssessmentResponseID' && key !== 'user_email' && key !== 'product_code' ) {
                        localStorage.removeItem( key );
                    }
                } );
            } else {
                localStorage.clear();
            }
            setIsModalOpen( false );
            setCurrentComponent( currentComponent + 1 );
        }
    }, [updateUserResponseData] )

    useEffect( () => {
        if ( studentError ) {
            setCurrentDynamicStep( currentDynamicStep - 1 );
            setIsModalOpen( false );
        }
    }, [studentError] );

    const handleCancel = () => {
        setCurrentDynamicStep( currentDynamicStep - 1 );
        setIsModalOpen( false );
    };

    const isWide = useMedia( '(max-width: 500px)' );

    return (
        <div className="flex justify-center items-center">
            <>
                <Helmet>
                    <link rel="icon" href={ clientConfig.favicon } />
                </Helmet>
                {
                    ( creatingSchool || creatingStudent || updateUserResponseLoading ) &&
                    <div className="overlay">
                        <Spin size="large" />
                    </div>
                }
                { ( isLoading || formConfigLoading ) ?
                    <div className="flex items-center justify-center h-screen">
                        <Lottie animationData={ animationData } loop autoplay style={ { width: '250px', height: '250px' } } />
                    </div>
                    :
                    <>
                        <Modal
                            title="Are you sure you want to submit?"
                            open={ isModalOpen }
                            onOk={ handleOk }
                            onCancel={ handleCancel }
                            cancelText="Go Back"
                            okText="Submit"
                            centered={ true }
                            okButtonProps={ { style: { backgroundColor: '#3b82f6' } } }
                        >
                            <p>(Once submitted, answers cannot be altered)</p>
                        </Modal>
                        <div className="container-fluid">
                            <div className="row row-height">
                                {
                                    isLeftSidebarVisible && clientConfig && formConfig ? <LeftSideBar data={ data } formConfig={ form_config_data } /> : ""
                                }
                                { isConsentForm && (
                                    <TestTermsAndConditions onSubmit={ handleProceedToQuiz } link={ terms_and_conditions } />
                                ) }
                                <div className={ `${ isLeftSidebarVisible ? 'col-xl-8 col-lg-8 content-right' : 'flex justify-center p-10' }` } id="start">
                                    <>
                                        <div className={ `${ showProgressBar ? "justify-start " : "justify-center " }${ isQuestionNavigation ? "w-3/4 h-full" : "flex flex-col w-full xl:w-3/4 lg:w-3/4 sm:w-full sm:gap-10 lg:gap-0" } ${ showTimer ? '' : isTest ? '' : '' }` }>

                                            <div id="top-wizard">
                                                {
                                                    !isFormSubmitted && showTimer && !isLoadingScreen ?
                                                        (
                                                            perQuestionTimer ?
                                                                <>
                                                                    <div className="flex justify-center gap-4 mb-3">
                                                                        <PerQuestionTimer testTimeInSeconds={ testTimeInSeconds } currentDynamicStep={ currentDynamicStep } timeCompleted={ () => setCurrentDynamicStep( currentDynamicStep + 1 ) } />
                                                                    </div>

                                                                </>
                                                                :
                                                                <>
                                                                    <div className="flex justify-center gap-4 mb-3">
                                                                        <TestTimer testTimeInSeconds={ testTimeInSeconds } />
                                                                    </div>
                                                                </>
                                                        ) : <></>
                                                }
                                            </div>
                                            <div className="p-4 pt-0 sm:p-4 h-full">
                                                {
                                                    currentFlowName === "questionnaire_view" && !isTest &&
                                                    <>
                                                        <ProgressBar
                                                            completed={ completedProgress }
                                                            maxCompleted={ 100 }
                                                            bgColor={ clientConfig.primary_color }
                                                            isLabelVisible={ false }
                                                            height={ 15 }
                                                            style={ {
                                                                display: 'content',
                                                                alignItems: 'initial',
                                                            } }
                                                        />
                                                        <p className="text-lg font-light mt-2">
                                                            <span style={ { color: clientConfig.primary_color } }>{ completedProgress }%</span> out of <span style={ { color: clientConfig.primary_color } }>100%</span> completed.
                                                        </p>
                                                    </>
                                                }

                                                <div className="mt-8 h-full">

                                                    {
                                                        currentFlowName === "questionnaire_view" &&
                                                        <>
                                                            { renderQuestions( currentDynamicStep ) }
                                                        </>
                                                    }
                                                    {
                                                        currentFlowName === "phone_verification" &&
                                                        <MobileVerification
                                                            onAnswerChange={ ( currentQuestionText, answer ) => handleVerificationType( currentQuestionText, answer, currentDynamicStep ) }
                                                            onNext={ handleVerificationTypeNext }
                                                            phoneNumber={ phoneNumber }
                                                            currentComponent={ () => setCurrentComponent( currentComponent + 1 ) }
                                                        />
                                                    }
                                                    {
                                                        currentFlowName === "email_verification" &&
                                                        <EmailVerification
                                                            onAnswerChange={ ( currentQuestionText, answer ) => handleVerificationType( currentQuestionText, answer, currentDynamicStep ) }
                                                            onNext={ handleVerificationTypeNext }
                                                            userEmail={ email }
                                                            currentComponent={ () => setCurrentComponent( currentComponent + 1 ) }
                                                        />
                                                    }
                                                    {
                                                        currentFlowName === "loading_screen" &&
                                                        <Review data={ data } />
                                                    }
                                                    {
                                                        currentFlowName === "coupon_code_verification" &&
                                                        navigate( `/${ client_name }/verification/coupon_code` )
                                                    }
                                                    {
                                                        currentFlowName === "instructions_page" &&
                                                        navigate( `/${ client_name }/test/${ student_grade_local_storage }/instructions` )
                                                    }
                                                    {
                                                        currentFlowName === "test_selection_view" &&
                                                        navigate( `/${ client_name }/test/selection` )
                                                    }
                                                    {
                                                        currentFlowName === "thank_you_page_view" && (
                                                            template_id ? navigate( `/${ client_name }/thank_you/?template_id=${ template_id }` ) : navigate( `/${ client_name }/thank_you` )
                                                        )
                                                    }

                                                    {
                                                        isWide ?
                                                            <button
                                                                className="fixed bottom-4 right-4 text-white p-2 rounded-full"
                                                                onClick={ () => {
                                                                    window.scrollTo( {
                                                                        top: document.documentElement.scrollHeight,
                                                                        behavior: 'smooth',
                                                                    } );
                                                                } }
                                                                style={ { backgroundColor: clientConfig.primary_color } }
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-6 w-6"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth={ 2 }
                                                                        d="M19 14l-7 7m0 0l-7-7m7 7V3"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            :
                                                            <></>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
        </div>
    );
};

export default MultiStepForm;