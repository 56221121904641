import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { textTypeCategory, textTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import '../../styles.css';
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams, useLocation } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';

const TextInputStep = ( { question, answer, onAnswerChange, onNext, onBack, isPagination, isQuestionNavigation, isValidation } ) => {
  const [isAnswerFilled, setIsAnswerFilled] = useState( false );
  const [firstName, setFirstName] = useState( "" );
  const { client_name, id } = useParams();

  const location = useLocation();
  const query = new URLSearchParams( location.search );
  const institute_code = dynamicData.default.institute_id;
  const clientConfig = useSelector( state => state.clientConfiguration );

  useEffect( () => {
    if ( answer ) {
      setFirstName( answer );
      setIsAnswerFilled( true )
      isValidation( true );
    } else if ( question.text.includes( "Check your school name" ) ) {
      setIsAnswerFilled( true )
      isValidation( true );
      setFirstName( localStorage.getItem( 'schoolName' ) )
    } else {
      setIsAnswerFilled( false )
    }
  }, [question, answer] )


  const handleKeyDownName = ( e ) => {
    // Prevent input of numeric values and special characters (except space)
    const regex = /^[A-Za-z\s]+$/;
    if ( !regex.test( e.key ) ) {
      e.preventDefault();
    }
    if ( e.key === "Enter" ) {
      onNext();
    }
    TrackGoogleAnalyticsEvent( textTypeCategory, textTypeAction );
  };

  const handleAnswerChange = ( value ) => {
    onAnswerChange( value );
    setFirstName( value );
    setIsAnswerFilled( value.trim() !== "" );
    isValidation( value.trim() !== "" );

    if ( question.text.includes( "Enter Your School Name" ) ) {
      localStorage.setItem( 'userSchoolName', value );
    } else if ( question.text.includes( "Who will serve as the iCAT coordinator from your school?" ) ) {
      localStorage.setItem( "contact_person_name", value )
    } else if ( question.text.includes( "What should we call you?" ) ) {
      localStorage.setItem( "studentName", value )
    }
  };

  const student_registration_route = dynamicData.default.student_registration_route;
  const perQuestionTimer = dynamicData.default.perQuestionTimer;

  return (
    <div className="flex flex-col mt-2 justify-between h-full">
      <div className="flex flex-col">
        <QuestionAndDescription question={ question } />
        <div>
          <input
            className="sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
            style={ { resize: "none", textAlign: "left" } }
            value={
              question.text.includes( "Check your school name" )
                ? localStorage.getItem( 'schoolName' )
                : firstName
            }
            onChange={ ( e ) =>
              //setFirstName( e.target.value );
              handleAnswerChange( e.target.value )
            }
            onKeyDown={ handleKeyDownName }
            placeholder={ question?.placeholder_text }
            disabled={ id === student_registration_route && question.text.includes( "Check your school name" ) }
          />
        </div>
      </div>
      {
        isPagination ?
          <></>
          :
          isQuestionNavigation ?
            <>
              <SubmitButton onNext={ onNext } />
            </>
            :
            <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
              {
                question?.question_optional === "optional" && (
                  <button className="skip-button" onClick={ onNext } >
                    Skip
                  </button>
                )
              }
              {
                perQuestionTimer ?
                  <></> :
                  <button className="back-button" onClick={ onBack } >
                    Back
                  </button>

              }
              <button
                className="next-button"
                onClick={ !isAnswerFilled ? null : onNext }
                style={ {
                  backgroundColor: !isAnswerFilled ? `${ clientConfig.primary_color }80` : `${ clientConfig.primary_color }`, // 80 is 50% opacity in hexadecimal
                  cursor: !isAnswerFilled ? 'not-allowed' : 'pointer'
                } }
              >
                Next
              </button>
            </div>
      }
    </div>
  );
};

export default TextInputStep;
