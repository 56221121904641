import React, { useState } from "react";
import { Slider } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { multipleChoiceCategory, multipleChoiceAction } from "../../utils/GoogleAnalytics/events/categories";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';

const marks = {};
for ( let i = 1; i <= 10; i++ ) {
    marks[i * 1] = `${ i * 1 }`;
}

const RangeTypeQuestion = ( {
    question,
    onAnswerChange,
    onNext,
    onBack,
    answer,
    isPagination,
    isQuestionNavigation,
    isValidation
} ) => {
    const [inputValue, setInputValue] = useState( answer ? answer : 1 );
    const clientConfig = useSelector( state => state.clientConfiguration );

    const onChange = ( newValue ) => {
        setInputValue( newValue );
        isValidation( true );
        onAnswerChange( newValue )
    };

    const { client_name } = useParams();

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    return (
        <div className="flex flex-col mt-2 justify-between h-full">
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div className="justify-content space-between w-full mt-5 mb-5">
                    <Slider marks={ marks } defaultValue={ inputValue } step={ 1 } max={ 10 } min={ 1 } onChange={ onChange } />
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="skip-button" onClick={ onNext } >
                                        Skip
                                    </button>
                                )
                            }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ inputValue ? onNext : null }
                                style={ {
                                    backgroundColor: inputValue ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`, // 80 is 50% opacity in hexadecimal
                                    cursor: inputValue ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default RangeTypeQuestion;
