export const indianStates = [
  'Andaman and Nicobar Islands', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam',
  'Bihar', 'Chandigarh', 'Chhattisgarh', 'Dadra and Nagar Haveli', 'Daman and Diu',
  'Delhi', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu', 'Jharkhand',
  'Karnataka', 'Kashmir', 'Kerala', 'Ladakh', 'Lakshadweep', 'Madhya Pradesh',
  'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Puducherry',
  'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttarakhand',
  'Uttar Pradesh', 'West Bengal',
];

export const dynamicData = {
  //"weace": {
  //"form_id": "239",
  //"institute_id": "379",
  //"document_title": "Stride Ahead",
  //"document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with. Unlock your potential today.",
  //"is_client_route": true,
  //"is_left_sideBar_visible": true,
  //"is_mobile_verification": true,
  //"is_email_verification": false,
  //"is_Loading_Screen": false,
  //"show_test_timer": false,
  //"perQuestionTimer": false,
  //"test_time_in_seconds": "",
  //"coupon_code_verification": false,
  //"is_pagination": false,
  //"test_verification_mobile": true,
  //"is_question_navigation": true,
  //"is_working_professional_form": false,
  //"is_student_form": false,
  //"is_parent_form": false,
  //"is_college_form": false,
  //"is_Loading_Screen": false,
  //"is_school_registration_form": true,
  //"is_student_registration_form": true,
  //"proceed_to_test": false,
  //"verify_with_email": false,
  //"school_registration_route": false,
  //"student_registration_route": true,
  //"test_time_in_seconds": "5400",
  //"class_6_test_route": "167",
  //"class_7_test_route": "168",
  //"class_8_test_route": "169",
  //"class_9_test_route": "170",
  //"class_10_test_route": "171",
  //"class_11PCM_test_route": "165",
  //"class_11PCB_test_route": "164",
  //"view_report": true,
  //"default_left_section": {
  //  "heading": "Data Science Fitment Assessment",
  //  "sub_heading": "Complete the quiz to unlock expert insights!",
  //  "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
  //  "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
  //  "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
  //},
  //"loading_page": {
  //  "heading": "Our AI is calculating your results...",
  //  "pointers": [
  //    "Gathering data for personality insights...",
  //    "Creating a fitment report just for you...",
  //    "Mapping your goals with our extensive database...",
  //    "Assessing your interests and aptitudes...",
  //    "Calculating the personalized next steps based on your responses...",
  //    "Finding the right recommmendaions for you—get ready!",
  //  ]
  //},
  //"main_test_content": {
  //  "thank_you_page": {
  //    "heading": "Congratulations 🎉",
  //    "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
  //    "sub_heading_two": "Ready to take the leap? Discover expert insights that will help you navigate your journey ahead!",
  //    "sub_heading_three": "You can give the test by clicking on the button below.",
  //  }
  //},
  //},
  "default": {
    "default_left_section": {
      "heading": "Data Science Fitment Assessment",
      "sub_heading": "Complete the quiz to unlock expert insights!",
      "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
      "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
      "additional_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    },
    "loading_page": {
      "left_section": {
        "heading": "Data Science Fitment Assessment",
        "sub_heading": "Complete the quiz to unlock expert insights!",
        "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
        "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
        "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
      },
      "heading": "Our AI is calculating your results...",
      "pointers": [
        "Gathering data for personality insights...",
        "Creating a fitment report just for you...",
        "Mapping your goals with our extensive database...",
        "Assessing your interests and aptitudes...",
        "Calculating the personalized next steps based on your responses...",
        "Finding the right recommmendaions for you—get ready!",
      ]
    },
    "sign_up_content": {
      "thank_you_page": {
        "heading": "Congratulations 🎉",
        "sub_heading_one": "Registration Done",
        "sub_heading_two": "Thankyou for registering.",
        "sub_heading_three": "You can give the test by clicking on the button below.",
      }
    },
    "main_test_content": {
      "thank_you_page": {
        "heading": "Congratulations 🎉",
        "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
        "sub_heading_two": "Ready to take the leap? Discover expert insights that will help you navigate your journey ahead!",
        //"sub_heading_three": "You can give the test by clicking on the button below.",
      }
    },
    "instructions_left_section": {
      "heading": "Brainwave Career Fit Assessment",
      "sub_heading": "Before you begin, read all the instructions for each section carefully to ensure accurate completion of your test.",
      "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
      "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
      "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahad"
    },
  },
  "46": {
    "default_left_section": {
      "heading": "Your Upskilling Return on Investment",
      "sub_heading": "Each question will help you understand the impact upskilling can have on your earnings and career.",
      "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
      "logo_link": "https://stride-survey-assets.s3.amazonaws.com/abc/logo.png",
      "additional_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    },
    "terms_and_conditions": "https://jaroeducation.com/terms-of-use/",
    "loading_page": {
      "heading": "Our algorithm is calculating your results...",
      "pointers": [
        "Gathering data for personalized insights...",
        "Mapping your goals with our extensive database...",
        "Calculating your return on investment...",
        "Mapping personalized next steps based on your responses...",
        "Finding the right resources for your goals...",
        "Creating a report just for you - get ready!",
      ]
    },
    "sign_up_content": {
      "thank_you_page": {
        "heading": "Congratulations 🎉",
        "sub_heading_one": "Registration Done",
        "sub_heading_two": "Thankyou for registering.",
        "sub_heading_three": "You can give the test by clicking on the button below.",
      }
    },
    "main_test_content": {
      "thank_you_page": {
        "heading": "Congratulations 🎉",
        "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
        "sub_heading_two": "Ready to take the leap?",
        "sub_heading_three": "Discover expert insights that will help you navigate your journey ahead!",
      }
    },
  },
  "42": {
    "default_left_section": {
      "heading": "Future Readiness Quiz",
      "sub_heading": "Buidling Bright Futures",
      "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
      "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
      "additional_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    },
    "terms_and_conditions": "https://jaroeducation.com/terms-of-use/",
    "loading_page": {
      "heading": "Calculating your results...",
      "pointers": [
        "Creating a report just for you...",
        "Mapping your goals...",
        "Assessing your interests and traits...",
        "Finding the right recommmendaions for you—get ready!",
      ]
    },
    "sign_up_content": {
      "thank_you_page": {
        "heading": "Congratulations 🎉",
        "sub_heading_one": "Registration Done",
        "sub_heading_two": "Thankyou for registering.",
        "sub_heading_three": "You can give the test by clicking on the button below.",
      }
    },
    "main_test_content": {
      "thank_you_page": {
        "heading": "Congratulations 🎉",
        "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
        "sub_heading_two": "Prepare to discover expert insights that will help you navigate your journey ahead!",
        "sub_heading_three": "You can give the test by clicking on the button below.",
      }
    },
  },
  //"Unitycollege": {
  //"form_id": "239",
  //"institute_id": "380",
  //"document_title": "Stride Ahead",
  //"document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with. Unlock your potential today.",
  //"is_client_route": true,
  //"is_left_sideBar_visible": true,
  //"is_mobile_verification": true,
  //"is_email_verification": false,
  //"is_Loading_Screen": false,
  //"show_test_timer": false,
  //"perQuestionTimer": false,
  //"test_time_in_seconds": "",
  //"coupon_code_verification": false,
  //"is_pagination": false,
  //"test_verification_mobile": true,
  //"is_question_navigation": true,
  //"is_working_professional_form": false,
  //"is_student_form": false,
  //"is_parent_form": false,
  //"is_college_form": false,
  //"is_Loading_Screen": false,
  //"is_school_registration_form": true,
  //"is_student_registration_form": true,
  //"proceed_to_test": false,
  //"verify_with_email": false,
  //"school_registration_route": false,
  //"student_registration_route": true,
  //"test_time_in_seconds": "5400",
  //"class_6_test_route": "167",
  //"class_7_test_route": "168",
  //"class_8_test_route": "169",
  //"class_9_test_route": "170",
  //"class_10_test_route": "171",
  //"class_11PCM_test_route": "165",
  //"class_11PCB_test_route": "164",
  //"view_report": true,
  //  "default_left_section": {
  //    "heading": "Data Science Fitment Assessment",
  //    "sub_heading": "Complete the quiz to unlock expert insights!",
  //    "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
  //    "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
  //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
  //  },
  //  "loading_page": {
  //    "left_section": {
  //      "heading": "Data Science Fitment Assessment",
  //      "sub_heading": "Complete the quiz to unlock expert insights!",
  //      "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
  //      "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
  //      "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
  //    },
  //    "heading": "Our AI is calculating your results...",
  //    "pointers": [
  //      "Gathering data for personality insights...",
  //      "Creating a fitment report just for you...",
  //      "Mapping your goals with our extensive database...",
  //      "Assessing your interests and aptitudes...",
  //      "Calculating the personalized next steps based on your responses...",
  //      "Finding the right recommmendaions for you—get ready!",
  //    ]
  //  },
  //  "sign_up_content": {
  //    "thank_you_page": {
  //      "heading": "Congratulations 🎉",
  //      "sub_heading_one": "Registration Done",
  //      "sub_heading_two": "Thankyou for registering.",
  //      "sub_heading_three": "You can give the test by clicking on the button below.",
  //    }
  //  },
  //  "main_test_content": {
  //    "thank_you_page": {
  //      "heading": "Congratulations 🎉",
  //      "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
  //      "sub_heading_two": "Ready to take the leap? Discover expert insights that will help you navigate your journey ahead!",
  //      //"sub_heading_three": "You can give the test by clicking on the button below.",
  //    }
  //  },
  //  "instructions_left_section": {
  //    "heading": "Brainwave Career Fit Assessment",
  //    "sub_heading": "Before you begin, read all the instructions for each section carefully to ensure accurate completion of your test.",
  //    "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
  //    "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
  //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahad"
  //  },
  //},
  //"internshala": {
  //"form_id": "229",
  //"institute_id": "367",
  //"document_title": "Stride Ahead",
  //"document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with. Unlock your potential today.",
  //"is_client_route": true,
  //"is_left_sideBar_visible": true,
  //"is_mobile_verification": true,
  //"is_email_verification": false,
  //"is_Loading_Screen": false,
  //"show_test_timer": false,
  //"perQuestionTimer": false,
  //"test_time_in_seconds": "",
  //"coupon_code_verification": false,
  //"is_pagination": false,
  //"test_verification_mobile": true,
  //"is_question_navigation": true,
  //"is_working_professional_form": false,
  //"is_student_form": false,
  //"is_parent_form": false,
  //"is_college_form": false,
  //"is_Loading_Screen": false,
  //"is_school_registration_form": true,
  //"is_student_registration_form": true,
  //"proceed_to_test": false,
  //"verify_with_email": false,
  //"school_registration_route": false,
  //"student_registration_route": true,
  //"test_time_in_seconds": "5400",
  //"class_6_test_route": "167",
  //"class_7_test_route": "168",
  //"class_8_test_route": "169",
  //"class_9_test_route": "170",
  //"class_10_test_route": "171",
  //"class_11PCM_test_route": "165",
  //"class_11PCB_test_route": "164",
  //"view_report": true,
  //"default_left_section": {
  //  "heading": "Data Science Fitment Assessment",
  //  "sub_heading": "Complete the quiz to unlock expert insights!",
  //  "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
  //  "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
  //  "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
  //},
  //"loading_page": {
  //  "left_section": {
  //    "heading": "Data Science Fitment Assessment",
  //    "sub_heading": "Complete the quiz to unlock expert insights!",
  //    "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
  //    "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
  //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
  //  },
  //  "heading": "Our AI is calculating your results...",
  //  "pointers": [
  //    "Gathering data for personality insights...",
  //    "Creating a fitment report just for you...",
  //    "Mapping your goals with our extensive database...",
  //    "Assessing your interests and aptitudes...",
  //    "Calculating the personalized next steps based on your responses...",
  //    "Finding the right recommmendaions for you—get ready!",
  //  ]
  //},
  //"sign_up_content": {
  //  "thank_you_page": {
  //    "heading": "Congratulations 🎉",
  //    "sub_heading_one": "Registration Done",
  //    "sub_heading_two": "Thankyou for registering.",
  //    "sub_heading_three": "You can give the test by clicking on the button below.",
  //  }
  //},
  //"main_test_content": {
  //  "thank_you_page": {
  //    "heading": "Congratulations 🎉",
  //    "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
  //    "sub_heading_two": "Ready to take the leap? Discover expert insights that will help you navigate your journey ahead!",
  //    "sub_heading_three": "You can give the test by clicking on the button below.",
  //  }
  //},
  //"instructions_left_section": {
  //  "heading": "Brainwave Career Fit Assessment",
  //  "sub_heading": "Before you begin, read all the instructions for each section carefully to ensure accurate completion of your test.",
  //  "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
  //  "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
  //  "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahad"
  //},
  //},
  //"jc": {
  //  "form_id": "191",
  //  "institute_id": "358",
  //  "document_title": "jc",
  //  "document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with - askIITians-iCAT. Unlock your potential today.",
  //  "is_client_route": true,
  //  "is_left_sideBar_visible": true,
  //  "is_mobile_verification": false,
  //  "is_email_verification": true,
  //  "is_Loading_Screen": false,
  //  "show_test_timer": false,
  //  "perQuestionTimer": false,
  //  "test_time_in_seconds": "",
  //  "coupon_code_verification": true,
  //  "is_pagination": false,
  //  "test_verification_mobile": true,
  //  "is_question_navigation": true,
  //  "is_working_professional_form": false,
  //  "is_student_form": false,
  //  "is_parent_form": false,
  //  "is_college_form": false,
  //  "is_Loading_Screen": false,
  //  "is_school_registration_form": true,
  //  "is_student_registration_form": true,
  //  "proceed_to_test": true,
  //  "verify_with_email": false,
  //  "school_registration_route": "105",
  //  "student_registration_route": "174",
  //  "test_time_in_seconds": "5400",
  //  "class_6_test_route": "167",
  //  "class_7_test_route": "168",
  //  "class_8_test_route": "169",
  //  "class_9_test_route": "170",
  //  "class_10_test_route": "171",
  //  "class_11PCM_test_route": "165",
  //  "class_11PCB_test_route": "164",
  //  "view_report": false,
  //  "default_left_section": {
  //    "heading": "Brainwave Career Fit Assessment",
  //    "sub_heading": "Sign up to unlock personalized career recommendations and expert guidance!",
  //    "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
  //    "logo_link": "https://i.ibb.co/pxt0cQ4/jitinchawlalogo.png",
  //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
  //  },
  //  "loading_page": {
  //    "left_section": {
  //      "heading": "Brainwave Career Fit Assessment",
  //      "sub_heading": "Your test is completed!",
  //      "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
  //      "logo_link": "https://i.ibb.co/pxt0cQ4/jitinchawlalogo.png",
  //      "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
  //    },
  //    "heading": "Our AI is calculating your results...",
  //    "pointers": [
  //      "Gathering data for personality insights...",
  //      "Mapping your interests with our extensive database...",
  //      "Assessing your aptitudes...",
  //      "Calculating the best career paths based on your responses...",
  //      "Creating a career report just for you...",
  //      "Finding the right next steps for you—get ready!",
  //    ]
  //  },
  //  "sign_up_content": {
  //    "thank_you_page": {
  //      "heading": "Congratulations 🎉",
  //      "sub_heading_one": "Registration Done",
  //      "sub_heading_two": "Thankyou for registering.",
  //      "sub_heading_three": "You can give the test by clicking on the button below.",
  //    }
  //  },
  //  "main_test_content": {
  //    "thank_you_page": {
  //      "heading": "Congratulations 🎉",
  //      "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
  //      "sub_heading_two": "Ready to take the leap? Discover expert insights that will help you navigate your journey ahead!",
  //      //"sub_heading_three": "You can give the test by clicking on the button below.",
  //    }
  //  },
  //  "instructions_left_section": {
  //    "heading": "Brainwave Career Fit Assessment",
  //    "sub_heading": "Before you begin, read all the instructions for each section carefully to ensure accurate completion of your test.",
  //    "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
  //    "logo_link": "https://i.ibb.co/pxt0cQ4/jitinchawlalogo.png",
  //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
  //  },
  //},
  //"deafult": {
  //  "document_title": "Stride Survey",
  //  "document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with. Unlock your potential today.",
  //  "left_section": {
  //    "heading": "Discover What's Best For You!",
  //    "sub_heading": "Know your skill gaps and discuss potential career paths with industry experts. Grab the chance to get that career boost you were always hoping for."
  //  },
  //},
  //"ttm": {
  //  "document_title": "Talk To Mentor",
  //  "document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with - Talk To Mentor. Unlock your potential today.",
  //"is_client_route": true,
  //"college_route": "223",
  //"working_professional_route": "225",
  //"is_student_form": false,
  //"is_parent_form": false,
  //"is_college_form": true,
  //"is_left_sideBar_visible": true,
  //"test_verification_mobile": true,
  //"coupon_code_verification": false,
  //"view_report": false,
  //"is_working_professional_form": true,
  //"is_mobile_verification": true,
  //"is_email_verification": false,
  //"is_Loading_Screen": true,
  //"show_test_timer": false,
  //"perQuestionTimer": false,
  //"test_time_in_seconds": "",
  //"is_pagination": false,
  //"is_question_navigation": true,
  //"proceed_to_test": false,
  //"verify_with_email": false,
  //  "school_registration_route": false,
  //  "student_registration_route": true,
  //  "default_left_section": {
  //    "heading": "Discover What's Best For You!",
  //    "sub_heading": "Know your skill gaps and discuss potential career paths with industry experts. Grab the chance to get that career boost you were always hoping for",
  //    "image_link": "https://i.ibb.co/2Wd5hN8/216.png",
  //    "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
  //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
  //  },
  //  "loading_page": {
  //    "heading": "Calculating your results.",
  //    "pointers": [
  //      "Initializing our AI Model.",
  //      "Accessing strengths and areas to improve.",
  //      "Finding a mentor to guide you.",
  //      "Analyzing best career path options.",
  //    ]
  //  },
  //  "thank_you_page": {
  //  }
  //},
  //"strideSurvey": {
  //  "document_title": "Tutoring Surveys",
  //  "document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with Tutoring Surveys. Unlock your potential today.",
  //  "is_client_route": true,
  //  "is_student_form": true,
  //  "is_parent_form": true,
  //  "is_college_form": false,
  //  "is_left_sideBar_visible": true,
  //  "is_working_professional_form": false,
  //  "is_mobile_verification": true,
  //  "is_email_verification": false,
  //  "is_Loading_Screen": true,
  //  "test_verification_mobile": true,
  //  "student_route": "65",
  //  "parent_route": "66",
  //  "view_report": false,
  //  "coupon_code_verification": false,
  //  "show_test_timer": false,
  //  "perQuestionTimer": false,
  //  "test_time_in_seconds": "",
  //  "is_pagination": false,
  //  "is_question_navigation": false,
  //  "proceed_to_test": false,
  //  "verify_with_email": false,
  //  "default_left_section": {
  //    "heading": "Stay Ahead of the Curve",
  //    "sub_heading": "Industries are changing rapidly. A well-planned career path can give your child the edge they need to stand out in a competitive job market. Complete the quiz and help your child stay ahead of the curve.",
  //    "image_link": "https://i.ibb.co/RzKhKKR/Info-Graphic.png",
  //    "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
  //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
  //  },
  //  "student_content": {
  //    "left_section": {
  //      "heading": "Study Quotient",
  //      "sub_heading": "Discover your Study Quotient in just minutes! Uncover your strengths and areas for growth across 8 key academic skills. Finish this quiz to level-up your study game!",
  //      "image_link": "https://i.ibb.co/RzKhKKR/Info-Graphic.png",
  //      "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
  //      "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
  //    },
  //    "loading_page": {
  //      "heading": "Our AI is thinking about your answers...",
  //      "pointers": [
  //        "Gathering data for personalized insights...",
  //        "Making a special career report just for you...",
  //        "Mapping your goals and needs with our extensive database...",
  //        "Calculating the best career paths based on your responses...",
  //        "Finding the right next steps for you—get ready!",
  //      ]
  //    },
  //    "thank_you_page": {
  //      "heading": "Congratulations on completing the Quiz!",
  //      "sub_heading_one": "You Rock for Taking the First Step!",
  //      "sub_heading_two": "Thanks for completing the assessment. You're on your way to unlocking a world of opportunities tailored just for you.",
  //      "multiple_sections": [
  //        {
  //          "heading": "Curious About Your Results?",
  //          "sub_heading": "Dive deep into your learning styles, strengths, and areas for improvement.",
  //          "cta_text": "Download Your Comprehensive PDF Report Here",
  //          "cta_links": "https://www.africau.edu/images/default/sample.pdf",
  //        },
  //        {
  //          "heading": "Ready to Level Up?",
  //          "sub_heading": "Get personalized guidance to help you navigate academic challenges and plan your career",
  //          "cta_text": "Book a One-on-One Mentorship Session",
  //          "cta_links": "https://booking.strideahead.in/",
  //        },
  //        {
  //          "heading": "Share the Wisdom: Get Your Parents Involved!",
  //          "sub_heading": "If they've already taken it, great! More customized mentorship is on the way for you.",
  //          "cta_text": "Tell Your Parent to Take the Assessment",
  //          "cta_links": "/nniit/66",
  //        },
  //        {
  //          "heading": "Exclusive Perks Just for You!",
  //          "sub_heading": "This is your gateway to exclusive content, offers, and much more.",
  //          "cta_text": "Check Out Our Special Offers",
  //          "cta_links": "https://strideahead.io/",
  //        },
  //      ],
  //    }
  //  },
  //  "parent_content": {
  //    "left_section": {
  //      "heading": "Stay Ahead of the Curve",
  //      "sub_heading": "Industries are changing rapidly. A well-planned career path can give your child the edge they need to stand out in a competitive job market. Complete the quiz and help your child stay ahead of the curve.",
  //      "image_link": "https://i.ibb.co/RzKhKKR/Info-Graphic.png",
  //      "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
  //      "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
  //    },
  //    "loading_page": {
  //      "heading": "Our AI is thinking about your answers...",
  //      "pointers": [
  //        "Analyzing your responses for personalized insights...",
  //        "Calculating data-driven guidance for your child's career...",
  //        "Crafting a roadmap based on your input...",
  //        "Finalizing tailored next steps—almost there!",
  //      ]
  //    },
  //    "thank_you_page": {
  //      "heading": "Thank You for Your Insight!",
  //      "sub_heading_one": "Your invaluable responses help us customize discussions and mentorship opportunities to align seamlessly with your child's career goals.",
  //      "multiple_sections": [
  //        {
  //          "heading": "Next Step: Help Your Child Unlock Their Full Potential",
  //          "sub_heading": "A comprehensive report will be generated that will immensely benefit your child in planning their future roadmap.",
  //          "cta_text": "Ask Your Child to Take the Study Quotient Assessment",
  //          "cta_links": "/nniit/65",
  //        },
  //        {
  //          "heading": "Already Taken the Assessment?",
  //          "sub_heading": "Our counseling team will reach out shortly to arrange a personalized session. Alternatively, feel free to book a slot in the calendar below.",
  //          "cta_text": "Book a Mentorship Session",
  //          "cta_links": "https://booking.strideahead.in/",
  //        },
  //        {
  //          "heading": "Special Offers Just for You ",
  //          "sub_heading": "",
  //          "cta_text": "Unlock Exclusive Content",
  //          "cta_links": "https://strideahead.io/",
  //        },
  //      ],
  //    }
  //  }
  //}
}

const imageData = [
  {
    id: 'option1',
    label: 'Image 1',
    imageUrl: 'https://c8.alamy.com/comp/2C86TRW/portrait-of-a-healthy-pleasant-looking-senior-woman-with-grey-hair-and-kind-face-looking-at-camera-while-posing-indoors-2C86TRW.jpg'
  },
  {
    id: 'option2',
    label: 'Image 2',
    imageUrl: 'https://c8.alamy.com/comp/2C86TRW/portrait-of-a-healthy-pleasant-looking-senior-woman-with-grey-hair-and-kind-face-looking-at-camera-while-posing-indoors-2C86TRW.jpg'
  },
  {
    id: 'option3',
    label: 'Image 3',
    imageUrl: 'https://c8.alamy.com/comp/2C86TRW/portrait-of-a-healthy-pleasant-looking-senior-woman-with-grey-hair-and-kind-face-looking-at-camera-while-posing-indoors-2C86TRW.jpg'
  }
];

export const imageQuestion = {
  text: "Select an image:",
  options: imageData
};

export const schoolData = {
  "jc": {
    "primary": {
      "test_Information": {
        "heading_one": <>• <b>Test Grade:</b></>,
        //"heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
        "heading_three": <>• <b>Number of Questions:</b> 105</>,
        "heading_four": <>• <b>Question Type:</b> MCQ</>,
      },
      "general_instructions": {
        "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
        "heading_one": "• This paper contains 105 questions divided into 2 segments. ",
        "segment_1": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest.</>,
        "segment_2": <>• <b>Segment -2 [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
        "heading_two": <>• <b>Questions marked in red [segment 1] are compulsory to answer. You won't be able to submit your assessment before answering all of these.</b></>,
        "heading_three": "• There is no internal choice in questions.",
        "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. Refrain from using any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device.",
        //"heading_four": <>• Test duration is<b> 45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
      },
      "segment_one_instructions": {
        "heading_one": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest </>,
        "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
        "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment.</b></>,
        "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
        "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
        //"heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
      },
      "segment_two_instructions": {
        "heading_one": <>• This segment<b> [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
        "heading_two": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
        //"heading_three": <>• <b>Section-B</b> contains 7 Aptitude & Logical reasoning based problems.</>,
        //"heading_four": <>• <b>Section-C </b> contains 3 Data Interpretation based problems.</>,
        //"heading_five": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
      },
      "marking_scheme": {
        "section_a": {
          //"heading" : "A",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
        //"section_b": {
        //"heading" : "B",
        //  "correct_attempt": "4",
        //  "incorrect_attempt": "-1",
        //  "unattempted": "0",
        //},
        //"section_c": {
        //"heading" : "B",
        //  "correct_attempt": "4",
        //  "incorrect_attempt": "-1",
        //  "unattempted": "0",
        //},
      }
    },
    "junior": {
      "test_Information": {
        "heading_one": <>• <b>Test Grade:</b></>,
        //"heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
        "heading_three": <>• <b>Number of Questions:</b> 105</>,
        "heading_four": <>• <b>Question Type:</b> MCQ</>,
      },
      "general_instructions": {
        "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
        "heading_one": "• This paper contains 105 questions divided into 2 segments. ",
        "segment_1": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest.</>,
        "segment_2": <>• <b>Segment -2 [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
        "heading_two": <>• <b>Questions marked in red [segment 1] are compulsory to answer. You won't be able to submit your assessment before answering all of these.</b></>,
        "heading_three": "• There is no internal choice in questions.",
        "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. Refrain from using any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device.",
        //"heading_four": <>• Test duration is<b> 45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
      },
      "segment_one_instructions": {
        "heading_one": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest </>,
        "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
        "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment.</b></>,
        "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
        "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
        //"heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
      },
      "segment_two_instructions": {
        "heading_one": <>• This segment<b> [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
        "heading_two": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
        //"heading_three": <>• <b>Section-B</b> contains 7 Aptitude & Logical reasoning based problems.</>,
        //"heading_four": <>• <b>Section-C </b> contains 3 Data Interpretation based problems.</>,
        //"heading_five": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
      },
      "marking_scheme": {
        "section_a": {
          //"heading" : "A",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
        //"section_b": {
        //  "correct_attempt": "4",
        //  "incorrect_attempt": "-1",
        //  "unattempted": "0",
        //},
        //"section_c": {
        //  "correct_attempt": "4",
        //  "incorrect_attempt": "-1",
        //  "unattempted": "0",
        //},
      }
    },
    "senior_pcm": {
      "test_Information": {
        "heading_one": <>• <b>Test Grade:</b></>,
        //"heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
        "heading_three": <>• <b>Number of Questions:</b> 105</>,
        "heading_four": <>• <b>Question Type:</b> MCQ</>,
      },
      "general_instructions": {
        "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
        "heading_one": "• This paper contains 105 questions divided into 2 segments. ",
        "segment_1": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest.</>,
        "segment_2": <>• <b>Segment -2 [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
        "heading_two": <>• <b>Questions marked in red [segment 1] are compulsory to answer. You won't be able to submit your assessment before answering all of these.</b></>,
        "heading_three": "• There is no internal choice in questions.",
        "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. Refrain from using any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device.",
        //"heading_four": <>• Test duration is<b> 45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
      },
      "segment_one_instructions": {
        "heading_one": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest </>,
        "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
        "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment.</b></>,
        "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
        "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
        //"heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
      },
      "segment_two_instructions": {
        "heading_one": <>• This segment<b> [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
        "heading_two": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
        //"heading_three": <>• <b>Section-B</b> contains 7 Aptitude & Logical reasoning based problems.</>,
        //"heading_four": <>• <b>Section-C </b> contains 3 Data Interpretation based problems.</>,
        //"heading_five": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
      },
      "marking_scheme": {
        "section_a": {
          //"heading" : "A",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
        //"section_b": {
        //  "correct_attempt": "4",
        //  "incorrect_attempt": "-1",
        //  "unattempted": "0",
        //},
        //"section_c": {
        //  "correct_attempt": "4",
        //  "incorrect_attempt": "-1",
        //  "unattempted": "0",
        //},
      }
    },
    "senior_pcb": {
      "test_Information": {
        "heading_one": <>• <b>Test Grade:</b></>,
        //"heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
        "heading_three": <>• <b>Number of Questions:</b> 105</>,
        "heading_four": <>• <b>Question Type:</b> MCQ</>,
      },
      "general_instructions": {
        "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
        "heading_one": "• This paper contains 105 questions divided into 2 segments. ",
        "segment_1": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest.</>,
        "segment_2": <>• <b>Segment -2 [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
        "heading_two": <>• <b>Questions marked in red [segment 1] are compulsory to answer. You won't be able to submit your assessment before answering all of these.</b></>,
        "heading_three": "• There is no internal choice in questions.",
        "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. Refrain from using any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device.",
        //"heading_four": <>• Test duration is<b> 45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
      },
      "segment_one_instructions": {
        "heading_one": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest </>,
        "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
        "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment.</b></>,
        "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
        "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
        //"heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
      },
      "segment_two_instructions": {
        "heading_one": <>• This segment<b> [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
        "heading_two": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
        //"heading_three": <>• <b>Section-B</b> contains 7 Aptitude & Logical reasoning based problems.</>,
        //"heading_four": <>• <b>Section-C </b> contains 3 Data Interpretation based problems.</>,
        //"heading_five": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
      },
      "marking_scheme": {
        "section_a": {
          //"heading" : "A",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
        //"section_b": {
        //  "correct_attempt": "4",
        //  "incorrect_attempt": "-1",
        //  "unattempted": "0",
        //},
        //"section_c": {
        //  "correct_attempt": "4",
        //  "incorrect_attempt": "-1",
        //  "unattempted": "0",
        //},
      }
    }
  },
  "icat": {
    "primary": {
      "test_Information": {
        "heading_one": <>• <b>Test Grade:</b></>,
        "heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
        "heading_three": <>• <b>Number of Questions:</b> 108</>,
        "heading_four": <>• <b>Question Type:</b> MCQ and Multi Select</>,
      },
      "general_instructions": {
        "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
        "heading_one": "• This paper contains 108 questions divided into 2 segments.",
        "segment_1": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest</>,
        "segment_2": <>• <b>Segment -2 [Ques 79-108]</b> contains 30 Aptitude Questions that are Subject-Knowledge based across Maths and Science, Aptitude & Logical reasoning based problems, and  Data Interpretation based problems.</>,
        "heading_two": <>• <b>All questions in segment 1 are compulsory to answer. To ensure your results are taken into account, prioritize completing this segment first.</b></>,
        "heading_three": "• There is no internal choice in questions.",
        "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. You are not allowed to use any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device. If you are found using any of the above items, it will be treated as resorting to unfair means and your current examination will be canceled. Further, you will be debarred from future examinations.",
        "heading_four": <>• Test duration is<b> 45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
      },
      "segment_one_instructions": {
        "heading_one": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest</>,
        "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
        "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment first.</b></>,
        "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
        "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
        "heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
      },
      "segment_two_instructions": {
        "heading_one": <>• This segment<b> [Ques 79-108]</b> contains 30 questions divided into 3 sections. </>,
        "heading_two": <>• <b>Section-A</b> contains 20 Subject-Knowledge Based questions across Maths and Science.</>,
        "heading_three": <>• <b>Section-B</b> contains 7 Aptitude & Logical reasoning based problems.</>,
        "heading_four": <>• <b>Section-C </b> contains 3 Data Interpretation based problems.</>,
        "heading_five": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
      },
      "marking_scheme": {
        "section_a": {
          "heading": "A",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
        "section_b": {
          "heading": "B",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
        "section_c": {
          "heading": "C",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
      }
    },
    "junior": {
      "test_Information": {
        "heading_one": <>• <b>Test Grade:</b></>,
        "heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
        "heading_three": <>• <b>Number of Questions:</b> 108</>,
        "heading_four": <>• <b>Question Type:</b> MCQ and Multi Select</>,
      },
      "general_instructions": {
        "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
        "heading_one": "• This paper contains 108 questions divided into 2 segments.",
        "segment_1": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest </>,
        "segment_2": <>• <b>Segment -2</b> contains 30 Aptitude Questions that are Subject-Knowledge based across Maths and Science, Aptitude & Logical reasoning based problems, and  Data Interpretation based problems.</>,
        "heading_two": <>• <b>All questions in segment 1 are compulsory to answer. To ensure your results are taken into account, prioritize completing this segment first.</b></>,
        "heading_three": "• There is no internal choice in questions.",
        "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. You are not allowed to use any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device. If you are found using any of the above items, it will be treated as resorting to unfair means and your current examination will be canceled. Further, you will be debarred from future examinations.",
        "heading_four": <>• Test duration is <b>45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
      },
      "segment_one_instructions": {
        "heading_one": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest </>,
        "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
        "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment first.</b></>,
        "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
        "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
        "heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
      },
      "segment_two_instructions": {
        "heading_one": <>• This segment<b> [Ques 79-108]</b> contains 30 questions divided into 3 sections. </>,
        "heading_two": <>• <b>Section-A</b> contains 24 Subject-Knowledge Based questions across Mathematics and Science.</>,
        "heading_three": <>• <b>Section-B</b> contains 6 Aptitude & Logical Reasoning based problems.</>,
        "heading_four": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
      },
      "marking_scheme": {
        "section_a": {
          "heading": "A",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
        "section_b": {
          "heading": "B",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
        "section_c": {
          "heading": "C",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
      }
    },
    "senior_pcm": {
      "test_Information": {
        "heading_one": <>• <b>Test Grade:</b></>,
        "heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
        "heading_three": <>• <b>Number of Questions:</b> 103</>,
        "heading_four": <>• <b>Question Type:</b> MCQ and Multi Select</>,
      },
      "general_instructions": {
        "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
        "heading_one": "• This paper contains 103 questions divided into 2 segments.",
        "segment_1": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest </>,
        "segment_2": <>• <b>Segment -2 [Ques 79-103]</b> contains 25 Aptitude Questions that are Subject-Knowledge based across Physics, Chemistry, Mathematics, Aptitude & Logical Reasoning based problems.</>,
        "heading_two": <>• <b>All questions in segment 1 are compulsory to answer. To ensure your results are taken into account, prioritize completing this segment first.</b></>,
        "heading_three": "• There is no internal choice in questions.",
        "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. You are not allowed to use any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device. If you are found using any of the above items, it will be treated as resorting to unfair means and your current examination will be canceled. Further, you will be debarred from future examinations.",
        "heading_four": <>• Test duration is <b>45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,

      },
      "segment_one_instructions": {
        "heading_one": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest </>,
        "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
        "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment first.</b></>,
        "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
        "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
        "heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
      },
      "segment_two_instructions": {
        "heading_one": <>• This segment<b> [Ques 79-103]</b> contains 25 questions divided into 3 sections. </>,
        "heading_two": <>• <b>Section-A</b> contains 21 Subject-Knowledge Based questions across Physics, Chemistry and Mathematics.</>,
        "heading_three": <>• <b>Section-B</b> contains 4 Aptitude & Logical Reasoning based problems.</>,
        "heading_four": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
      },
      "marking_scheme": {
        "section_a": {
          "heading": "A",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
        "section_b": {
          "heading": "B",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
        "section_c": {
          "heading": "C",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
      }
    },
    "senior_pcb": {
      "test_Information": {
        "heading_one": <>• <b>Test Grade:</b></>,
        "heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
        "heading_three": <>• <b>Number of Questions:</b> 103</>,
        "heading_four": <>• <b>Question Type:</b> MCQ and Multi Select</>,
      },
      "general_instructions": {
        "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
        "heading_one": "• This paper contains 103 questions divided into 2 segments.",
        "segment_1": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest </>,
        "segment_2": <>• <b>Segment -2 [Ques 79-103]</b>  contains 25 Aptitude Questions that are Subject-Knowledge based across Physics, Chemistry, Biology, Aptitude & Logical Reasoning based problems.</>,
        "heading_two": <>• <b>All questions in segment 1 are compulsory to answer. To ensure your results are taken into account, prioritize completing this segment first</b></>,
        "heading_three": "• There is no internal choice in questions.",
        "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. You are not allowed to use any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device. If you are found using any of the above items, it will be treated as resorting to unfair means and your current examination will be canceled. Further, you will be debarred from future examinations.",
        "heading_four": <>• Test duration is <b>45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
      },
      "segment_one_instructions": {
        "heading_one": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest </>,
        "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
        "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment first.</b></>,
        "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
        "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
        "heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
      },
      "segment_two_instructions": {
        "heading_one": <>• This segment<b> [Ques 79-103]</b> contains 25 questions divided into 3 sections. </>,
        "heading_two": <>• <b>Section-A</b> contains 21 Subject-Knowledge Based questions across Physics, Chemistry and Biology. </>,
        "heading_three": <>• <b>Section-B</b> contains 4 Aptitude & Logical Reasoning based problems.</>,
        "heading_four": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
      },
      "marking_scheme": {
        "section_a": {
          "heading": "A",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
        "section_b": {
          "heading": "B",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
        "section_c": {
          "heading": "C",
          "correct_attempt": "4",
          "incorrect_attempt": "-1",
          "unattempted": "0",
        },
      }
    }
  }
}