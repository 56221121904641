import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { textTypeCategory, textTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import '../../styles.css';
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import { useSelector } from 'react-redux';

const NameInputStep = ( { question, answer, onAnswerChange, onNext, onBack, isPagination, isQuestionNavigation, isValidation } ) => {
  const [isAnswerFilled, setIsAnswerFilled] = useState( false );
  const [firstName, setFirstName] = useState( "" );
  const clientConfig = useSelector( state => state.clientConfiguration );

  useEffect( () => {
    setFirstName( answer );
  }, [question] );

  useEffect( () => {
    if ( answer ) {
      setIsAnswerFilled( true );
      isValidation( true );
      setFirstName( answer );
    } else {
      setIsAnswerFilled( false )
      isValidation( false );
    }
  }, [question, answer] )


  const handleKeyDownName = ( e ) => {
    // Prevent input of numeric values and special characters (except space)
    const regex = /^[A-Za-z\s]+$/;
    if ( !regex.test( e.key ) ) {
      e.preventDefault();
    }
    if ( e.key === "Enter" ) {
      onNext();
    }
    TrackGoogleAnalyticsEvent( textTypeCategory, textTypeAction );
  };

  const handleAnswerChange = ( value ) => {
    const regex = /^[A-Za-z\s]*$/;
    if ( regex.test( value ) ) {
      onAnswerChange( value );
      setFirstName( value );
      setIsAnswerFilled( value.trim() !== "" );
      isValidation( true );
    }
  };

  const { client_name } = useParams();


  const perQuestionTimer = dynamicData.default.perQuestionTimer;

  return (
    <div className="flex flex-col mt-2 justify-between h-full">
      <div className="flex flex-col">
        <QuestionAndDescription question={ question } />
        <div>
          <input
            className="sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
            style={ { resize: "none", textAlign: "left" } }
            value={ firstName }
            onChange={ ( e ) => handleAnswerChange( e.target.value ) }
            onKeyDown={ handleKeyDownName }
            placeholder={ question?.placeholder_text }
          />
        </div>
      </div>
      {
        isPagination ?
          <></>
          :
          <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
            {
              question?.question_optional === "optional" && (
                <button className="skip-button" onClick={ onNext } >
                  Skip
                </button>
              )
            }
            {
              perQuestionTimer ?
                <></> :
                <button className="back-button" onClick={ onBack } >
                  Back
                </button>

            }
            <button
              className="next-button"
              onClick={ !isAnswerFilled ? null : onNext }
              style={ {
                backgroundColor: !isAnswerFilled ? `${ clientConfig.primary_color }80` : `${ clientConfig.primary_color }`, // 80 is 50% opacity in hexadecimal
                cursor: !isAnswerFilled ? 'not-allowed' : 'pointer'
              } }
            >
              Next
            </button>
          </div>
      }
    </div>
  );
};

export default NameInputStep;
