import React, { useEffect } from 'react';
import LeftSideBar from '../LeftSideBar';
import { Spin, Tooltip } from 'antd';
import useFetchFormsByUserId from '../../api/FetchFormsUserId';
import { useParams, Link } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled, LockFilled } from '@ant-design/icons';

const TestSelection = () => {

    const { response: formsData, isLoading: formsdataLoading, FetchUserForms } = useFetchFormsByUserId();
    const { client_name } = useParams();
    const user_id = localStorage.getItem( 'user_id' ) || localStorage.getItem( 'student_roll_number' );

    useEffect( () => {
        sessionStorage.clear();
        Object.keys( localStorage ).forEach( ( key ) => {
            if ( key !== 'student_roll_number' && key !== 'user_id' ) {
                localStorage.removeItem( key );
            }
        } );
    }, [] )
    useEffect( () => {
        if ( formsData ) {
            localStorage.setItem( 'user_id', formsData.user_id );
        }
    }, [formsData] )

    useEffect( () => {
        if ( client_name ) {
            FetchUserForms( client_name, user_id, 'test' )
        }
    }, [client_name] )

    return (
        <div className="container-fluid">
            <div className="row row-height">
                {
                    formsdataLoading &&
                    <div className="overlay">
                        <Spin size="large" />
                    </div>
                }
                <LeftSideBar />
                <div className="col-xl-8 col-lg-8 content-right" id="start">
                    <>
                        <div className="flex items-center justify-center w-3/4 flex-col">
                            <div className='border-2 w-full rounded-t-xl overflow-hidden'>
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr className='bg-gray-700 text-white text-xl font-semibold'>
                                            <th className="text-center px-4 py-2 border-r-2">S.no</th>
                                            <th className="text-center px-4 py-2 border-r-2">Test Name</th>
                                            <th className="text-center px-4 py-2 border-r-2">Test Links</th>
                                            <th className="text-center px-4 py-2 border-r-2">Instructions</th>
                                            <th className="text-center px-4 py-2">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { formsData?.forms?.map( ( test, index ) => (
                                            <tr key={ test.id }>
                                                <td className="px-4 py-3 text-center text-xl border-r-2">{ index + 1 }</td>
                                                <td className="px-4 py-3 text-center text-xl border-r-2">{ test.form_name }</td>
                                                <td className="px-4 py-3 text-center text-xl border-r-2">
                                                    { test.user_assessment_status === 3 ? (
                                                        <button
                                                            type='button'
                                                            className="bg-blue-400 cursor-not-allowed text-white p-2 rounded-lg"
                                                            style={ { cursor: 'not-allowed' } }
                                                        >
                                                            Take Test
                                                        </button>
                                                    ) : (
                                                        <Link
                                                            to={ `/${ client_name }/test/${ test.test_id }` }
                                                        >
                                                            <button
                                                                type='button'
                                                                className="text-white bg-blue-500 px-2 rounded-lg py-2"
                                                            >
                                                                Take Test
                                                            </button>
                                                        </Link>

                                                    ) }

                                                </td>
                                                <td className="px-4 py-3 text-center text-xl border-r-2">
                                                    <button

                                                        className='text-white bg-blue-500 px-2 rounded-lg py-2'
                                                    >
                                                        Instructions
                                                    </button>
                                                </td>
                                                <td className="px-4 py-3 text-center text-xl">
                                                    { test.user_assessment_status === 3 ? (
                                                        <CheckCircleFilled style={ { color: 'green' } } />
                                                    ) : (
                                                        <CloseCircleFilled style={ { color: 'red' } } />
                                                    ) }
                                                </td>
                                            </tr>
                                        ) ) }
                                    </tbody>
                                </table>
                            </div>
                            { formsData?.forms?.every( ( test ) => test.user_assessment_status === 3 ) ? (
                                <Link
                                    to={ `/${ client_name }/thank_you/?completed=true` }
                                >
                                    <button className="text-white text-center align-center bg-blue-500 px-2 rounded-lg py-2 mt-4 text-xl">View My Report</button>
                                </Link>
                            ) : (
                                <div className="flex items-center justify-center mt-4">
                                    <Tooltip title="Please complete all the tests" placement='bottom'>

                                        <button
                                            className="text-black bg-yellow-300 opacity-50 px-2 rounded-lg py-2 text-xl text-center align-center">
                                            <LockFilled style={ { color: 'black' } } className='mr-2 align-middle' />
                                            View My Report
                                        </button>
                                    </Tooltip>
                                </div>
                            ) }
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};

export default TestSelection
