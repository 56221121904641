import React from 'react'
import TestComleted from '../../assets/all done.svg';

const TestSubmissionPage = () => {
    return (
        <>
            <div className="mt-2 w-full">
                <img src={ TestComleted } preview={ false } className='w-full' />
            </div>
        </>
    )
}

export default TestSubmissionPage