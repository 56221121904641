import React, { useState, useEffect } from 'react';
import { dynamicData } from '../../helpers/data';
import { notification, Spin } from 'antd';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';
import { useSelector } from 'react-redux';
import ThankYouPageOne from './ThankYouPageOne';

export const ThankYouUnityCollege = () => {

    const student_name = localStorage.getItem( 'studentName' ) || "abc";
    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";
    const [studentRollNumber, setStudentRollNumber] = useState( null );
    const formConfigRedux = useSelector( state => state.formConfiguration );

    const formConfig = formConfigRedux?.form_config;
    const formid = formConfig.form_id;

    const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();

    const handleBooking = () => {
        window.open( `https://bookings.strideahead.io/team-stride/idsm-mentorship-session/?name=${ student_name }&email=${ student_email }`, '_blank' );
    }

    useEffect( () => {
        if ( studentRollNumber ) {
            generatePdfReport( studentRollNumber, formid )
        }
    }, [studentRollNumber] )

    const handleDownloadReport = async () => {
        setStudentRollNumber( localStorage.getItem( 'student_roll_number' ) );
    }

    useEffect( () => {
        if ( pdfReport ) {
            const downloadLink = document.createElement( "a" );
            downloadLink.style.display = "none";
            downloadLink.href = pdfReport;
            downloadLink.download = "Data-Science-Report.pdf"; // Specify the filename for the download

            // Append the anchor element to the document body
            document.body.appendChild( downloadLink );

            // Trigger a click event on the anchor to start the download
            downloadLink.click();

            // Remove the anchor from the document
            document.body.removeChild( downloadLink );
        }
    }, [pdfReport] )

    return (
        <>
            {
                ( pdfReportGenerating ) ?
                    <div className="overlay">
                        <Spin size="large" />
                    </div>
                    :
                    <ThankYouPageOne
                        handleReport={ handleDownloadReport }
                        handlementorshipbooking={ handleBooking }
                    />
            }
        </>
    );
};

