import { useState } from 'react';
import axios from 'axios';
import { BASE_URL, SEND_MESSAGE } from '../utils/constants/constants';

const useSendMessageUser = () => {
    const [data, setData] = useState( null );
    const [isLoading, setIsLoading] = useState( false );
    const [error, setError] = useState( null );

    const SendMessageUser = async ( studentRollNumber, formId ) => {
        setIsLoading( true );
        setError( null );

        try {
            const response = await axios.post( `${ BASE_URL }${ SEND_MESSAGE }?student_roll_number=${ studentRollNumber }&form_id=${ formId }` );

            setData( response.data );
            setIsLoading( false );
        } catch ( err ) {
            setError( err );
            setIsLoading( false );
        }
    };

    return { data, isLoading, error, SendMessageUser };
};

export default useSendMessageUser;