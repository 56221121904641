import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import { notification, Spin } from 'antd';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';
import { useSelector } from 'react-redux';

export const ThankYouInternshala = () => {

    const { client_name } = useParams();
    const student_name = localStorage.getItem( 'studentName' ) || "abc";
    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";
    const [studentRollNumber, setStudentRollNumber] = useState( null );
    const formConfigRedux = useSelector( state => state.formConfiguration );

    const formConfig = formConfigRedux?.form_config;
    const clientid = formConfig.client_id
    const formid = formConfig.form_id;

    const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();

    const handleBooking = () => {
        window.open( `https://bookings.strideahead.io/team-stride/idsm-mentorship-session/?name=${ student_name }&email=${ student_email }`, '_blank' );
    }

    useEffect( () => {
        if ( studentRollNumber ) {
            generatePdfReport( studentRollNumber, formid )
        }
    }, [studentRollNumber] )

    const handleDownloadReport = async () => {
        setStudentRollNumber( localStorage.getItem( 'student_roll_number' ) );
    }

    useEffect( () => {
        if ( pdfReport ) {
            const downloadLink = document.createElement( "a" );
            downloadLink.style.display = "none";
            downloadLink.href = pdfReport;
            downloadLink.download = "Data-Science-Report.pdf"; // Specify the filename for the download

            // Append the anchor element to the document body
            document.body.appendChild( downloadLink );

            // Trigger a click event on the anchor to start the download
            downloadLink.click();

            // Remove the anchor from the document
            document.body.removeChild( downloadLink );
        }
    }, [pdfReport] )

    let thankYouPage =
        formConfig.main_test_content?.thank_you_page ||
        dynamicData[clientid]?.main_test_content?.thank_you_page ||
        dynamicData.default?.main_test_content?.thank_you_page;

    let heading = thankYouPage?.heading || "";
    let sub_heading_one = thankYouPage?.sub_heading_one || "";
    let sub_heading_two = thankYouPage?.sub_heading_two || "";
    let sub_heading_three = thankYouPage?.sub_heading_three || "";

    return (
        <>
            {
                pdfReportGenerating ?
                    <div className="overlay">
                        <Spin size="large" />
                    </div>
                    :
                    <div className="p-8 bg-white shadow-lg rounded-lg max-w-xl mx-auto text-center">
                        <h1 className="text-2xl font-bold mb-4">{ heading }</h1>
                        <p className="text-xl text-black font-bold mb-4">{ sub_heading_one }</p>
                        <button className="bg-blue-500 text-white text-center rounded py-2 px-4 mb-2 hover:bg-blue-600" onClick={ handleDownloadReport }>
                            Download your PDF report
                        </button>
                        <h1 className="text-xl text-black font-bold mb-4 mt-5">{ sub_heading_two }</h1>
                        <button onClick={ handleBooking } className="bg-blue-500 text-white text-center rounded py-2 px-4 mb-2 hover:bg-blue-600">
                            Book Your Mentorship Session!
                        </button>
                    </div>
            }
        </>
    );
};

