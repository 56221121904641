import { useMedia } from 'react-use';
import React, { useState, useEffect } from 'react';

const useImageDimensions = ( src, maxWidth, maxHeight ) => {
    const [dimensions, setDimensions] = useState( { width: 0, height: 0 } );

    useEffect( () => {
        const image = new Image();

        image.onload = () => {
            const aspectRatio = image.width / image.height;

            let newHeight = maxHeight;
            let newWidth = maxHeight * aspectRatio;

            if ( newWidth > maxWidth ) {
                newWidth = maxWidth;
                newHeight = maxWidth / aspectRatio;
            }

            setDimensions( { width: newWidth, height: newHeight } );
        };

        image.src = src;
    }, [src, maxWidth, maxHeight] );

    return dimensions;
};

const QuestionAndDescription = ( { question } ) => {
    const maxWidth = window.innerWidth - 75;
    const isWide = useMedia( '(min-width: 450px)' );
    const maxHeight = isWide ? 300 : 150;

    const getIdFromDriveLink = ( driveLink ) => {
        if ( driveLink ) {
            const idMatch = driveLink.match( /\/d\/([^/]+)\// );
            if ( idMatch && idMatch[1] ) {
                return idMatch[1];
            }
        }
        return null;
    };

    const imgSrcMatch = question.text.match( /src=["'](.*?)["']/ );

    const imgSrc = imgSrcMatch && imgSrcMatch[1];

    const imageId = getIdFromDriveLink( imgSrc );

    const ImageSource = imgSrc && imgSrc.includes( "drive.google.com" )
        ? `https://drive.google.com/uc?export=view&id=${ imageId }`
        : imgSrc;

    const dimensions = useImageDimensions( ImageSource, maxWidth, maxHeight );

    const renderQuestionText = () => {
        let modifiedText = question?.text || '';

        // Check if question text includes <img
        if ( modifiedText.includes( "<img" ) ) {
            modifiedText = modifiedText.replace(
                /<img/g,
                `<img src=${ ImageSource } style="max-width: ${ dimensions.width }px; max-height: ${ dimensions.height }px;"`
            );
        }

        // Check if question text includes \n and replace it with <br>
        if ( modifiedText.includes( "\n" ) ) {
            modifiedText = modifiedText.replace( /\n/g, '<br>' );
        }

        return <span dangerouslySetInnerHTML={ { __html: modifiedText } } />;
    };

    return (
        <div className='font-poppins'>
            <h1 className="font-poppins lg:text-2xl sm:text-lg font-bold bg-white mb-2 select-none">
                Question no. { question?.index }
            </h1>
            <h1 className="font-poppins lg:text-2xl sm:text-lg font-medium bg-white mb-2 mt-4 select-none">
                { renderQuestionText() }
            </h1>
            {
                question?.description &&
                <h1 className="font-poppins lg:text-sm sm:text-xs font-medium text-[#374151] mb-4 mt-2 select-none bg-[#ffefeb] w-fit px-3 py-2 rounded-full">
                    { question?.description }
                </h1>
            }
        </div>
    );
};

export default QuestionAndDescription;