import React, { useState, useEffect, useRef } from "react";
import { useMedia } from 'react-use';
import "../css/style.css";
import "../css/bootstrap.min.css";
import Logo from "../img/logo.png"
import { Image, Spin, Tooltip, Modal, notification } from 'antd';
import { dynamicData } from "../helpers/data";
import { useParams } from "react-router-dom";
import { setIsStudent, setIsParent, setIsSchoolRegistration, setIsStudentRegistration, setQuestionNumber, setIsFormSubmitted, setIsLoadingScreen, setIsRequiredQuestionsAttempted } from '../redux/student/studentSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    ControlOutlined,
    FlagFilled, InsertRowAboveOutlined
} from '@ant-design/icons';
import SegmentInstructions from "./InstructionPage/SegmentInstructions";
import updateUserResponse from "../api/useUpdateUserResponse";
import jarologo from '../assets/jarologohd.svg';
import mainBanneLink from '../img/main_banner_image.png';

const ConfirmationModal = ( { isOpen, onOk, onCancel } ) => {
    return (
        <>
            <Modal title="Are you sure you want to submit?" open={ isOpen } onOk={ onOk } onCancel={ onCancel } okButtonProps={ { style: { backgroundColor: 'green', color: 'white' } } } okText="Submit" centered>
                <p>Once submitted, answers cannot be altered.</p>
            </Modal>
        </>
    );
};


const LeftSideBar = ( { data, formConfig } ) => {
    const { client_name, user_type, grade } = useParams();

    const isStudent = user_type === 'student' ? true : false;
    const isParent = user_type === 'parent' ? true : false;;
    const isSchoolRegistration = useSelector( state => state.schoolRegistration.isSchoolRegistration )
    const isStudentRegistration = useSelector( state => state.studentRegistration.isStudentRegistration )
    const isRequiredQuestionsAttempted = useSelector( state => state.requiredQuestionsAttempted.isRequiredQuestionsAttempted )
    const questionNumber = useSelector( state => state.currentQuestion.questionNumber );
    const textAnswers = useSelector( ( state ) => state.answersSlice.textAnswers );
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState( null );
    const [activeQuestionIndex, setActiveQuestionIndex] = useState( null );
    const [isModalOpen, setIsModalOpen] = useState( false );
    const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState( false );

    const isFormSubmitted = useSelector( state => state.formSubmitted.isFormSubmitted );
    const isLoadingScreen = useSelector( state => state.loadingScreen.isLoadingScreen );
    const clientConfig = useSelector( state => state.clientConfiguration );
    const formConfigRedux = useSelector( state => state.formConfiguration );
    const isWide = useMedia( '(max-width: 500px)' );

    const formConfigData = formConfigRedux.form_config;
    //const formConfig = formConfigRedux ? formConfigRedux?.formConfig[0] : "";

    const client_id = clientConfig.client_id;

    const userAssessmentResponseID = localStorage.getItem( 'userAssessmentResponseID' );

    const { response: updateUserResponseData, isLoading: updateUserResponseLoading, error: updateUserResponseError, updateResponse } = updateUserResponse();

    const showModal = () => {
        setIsModalOpen( true );
    };

    const handleOk = () => {
        setIsModalOpen( false );
    };

    const handleCancel = () => {
        setIsModalOpen( false );
    };

    const showInstructionsModal = () => {
        setIsInstructionsModalOpen( true );
    }

    const handleCloseInstructionsModal = () => {
        setIsInstructionsModalOpen( false );
    };

    const numberOfQuestions = data?.questions?.length || 0;

    const isQuestionNavigation = formConfig?.is_question_navigation || ( formConfigRedux?.formConfig ? formConfigRedux?.form_config.isQuestionNavigation : "" );

    const dispatch = useDispatch();
    const [flaggedQuestions, setFlaggedQuestions] = useState( [] );

    const handleButtonClick = ( index ) => {
        setSelectedQuestionIndex( index );
        dispatch( setQuestionNumber( index ) );
        localStorage.setItem( 'questionNumber', index );
    };

    const handleFinishAttempt = () => {
        if ( isRequiredQuestionsAttempted ) {
            updateResponse( { id: userAssessmentResponseID, is_completed: 3, clientName: client_name } )
            dispatch( setIsLoadingScreen( true ) );
            localStorage.setItem( 'isLoadingScreen', true )
            setIsModalOpen( false );
        } else {
            notification.error( {
                message: 'Unable to submit!',
                description: 'Please attempt all the required questions to submit the test.',
            } );
        }
    }

    const buttonRefs = useRef( [] );

    useEffect( () => {
        setActiveQuestionIndex( questionNumber )
        //if ( questionNumber >= 0 && questionNumber < numberOfQuestions ) {
        //    buttonRefs.current[questionNumber]?.focus();
        //    buttonRefs.current[activeQuestionIndex]?.focus();
        //}
    }, [questionNumber, numberOfQuestions, activeQuestionIndex] );

    useEffect( () => {
        const requiredQuestions = data?.questions.filter( question => question.question_optional === 'required' );
        const allRequiredAttempted = requiredQuestions?.every( ( question, index ) => textAnswers[index] );

        dispatch( setIsRequiredQuestionsAttempted( allRequiredAttempted ) );
    }, [textAnswers, data] );

    return (
        <>
            {
                isQuestionNavigation ?
                    <>
                        <div className="bg-[#CCCCCC] p-0 col-xl-4 col-lg-4">
                            <div className="flex flex-col justify-center items-center h-full min-h-full p-16 pb-9 text-white text-center relative md:h-auto md:p-[95px,30px,35px,30px] lg:p-[95px,15px,35px,15px]">
                                <div className="w-full flex justify-center mb-4">
                                    <button
                                        className="rounded-md text-white p-3 mb-2 bg-blue-500"
                                        onClick={ showInstructionsModal }
                                    >
                                        Instructions
                                    </button>
                                    <SegmentInstructions isOpen={ isInstructionsModalOpen } onOk={ handleCloseInstructionsModal } />
                                </div>
                                <div className="w-full flex justify-evenly mb-4 gap-1">
                                    <Tooltip placement="top" title="You have the option to mark questions for a review later. These questions will still be included in the scoring.">
                                        <button
                                            className="bg-white text-[#3F52FD] rounded px-4 py-2 font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#3F52FD] focus:ring-white"
                                            onClick={ () => {
                                                setFlaggedQuestions( [...flaggedQuestions, questionNumber] );
                                            } }
                                        >
                                            Mark For Review
                                        </button>
                                    </Tooltip>
                                    <Tooltip placement="top" title="This removes the 'Mark for Review' indication from a question.">
                                        <button
                                            className="bg-white text-[#3F52FD] rounded px-4 py-2 font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#3F52FD] focus:ring-white"
                                            onClick={ () => {
                                                setFlaggedQuestions(
                                                    flaggedQuestions.filter( ( q ) => q !== questionNumber )
                                                );
                                            } }
                                        >
                                            Clear Review
                                        </button>
                                    </Tooltip>
                                    {/*<button
                                        className="bg-white text-[#3F52FD] rounded px-4 py-2 font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#3F52FD] focus:ring-white"
                                        onClick={ showModal }>
                                        Finish attempt
                                    </button>
                                    <ConfirmationModal isOpen={ isModalOpen } onOk={ handleFinishAttempt } onCancel={ handleCancel } />*/}
                                </div>
                                <div className="flex flex-wrap gap-2">
                                    { Array.from( { length: numberOfQuestions }, ( _, index ) => (
                                        <button
                                            key={ index }
                                            ref={ ( el ) => ( buttonRefs.current[index] = el ) }
                                            className={ `w-10 h-10 text-[#3F52FD] font-bold focus:outline-none
                                                        ${ data?.questions[index]?.question_optional === 'required' ? ( textAnswers[index] ? 'bg-green-500 text-[#000000] rounded-full' : 'bg-red-200 text-[#000000] rounded-full' ) : ( textAnswers[index] ? 'bg-green-500 text-[#000000] rounded-full' : 'bg-white text-[#000000] rounded-full' ) }
                                                        ${ activeQuestionIndex === index ? 'ring-2 ring-offset-2 ring-offset-[#3F52FD] ring-red' : '' }
                                                        ${ questionNumber === index ? 'focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#3F52FD] focus:ring-red' : '' }
                                                        ${ flaggedQuestions.includes( index ) ? 'relative' : '' }` }
                                            onClick={ () => {
                                                handleButtonClick( index );
                                                setSelectedQuestionIndex( index );
                                            } }
                                        >
                                            { index + 1 }
                                            { flaggedQuestions.includes( index ) && (
                                                <FlagFilled className="absolute top-0 right-0 text-red-500" />
                                            ) }
                                        </button>
                                    ) ) }
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {
                            isWide ?
                                <div style={ { backgroundColor: clientConfig.primary_color || "#3482F6" } } className='p-0 col-xl-4 col-lg-4 font-poppins'>
                                    <div className="flex flex-col justify-start items-center h-full text-white text-center pt-0 p-4">
                                        <div className="flex justify-center items-center w-1/2 h-20 bg-white rounded-b-3xl w-full">
                                            <div
                                                className="flex justify-center items-center h-[150px] sm:h-[200px] w-[250px] sm:w-[300px]"
                                                style={ { maxWidth: "100%", maxHeight: "100%" } }
                                            >
                                                <img
                                                    src={ clientConfig?.logo }
                                                    alt="logo"
                                                    className="max-h-full max-w-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={ { backgroundColor: clientConfig.primary_color || "#3482F6" } } className='p-0 col-xl-4 col-lg-4 font-poppins'>
                                    <div className="flex flex-col justify-start items-center h-full text-white text-center relative">
                                        <div className="flex justify-center items-center w-fit h-24 bg-white rounded-b-3xl">
                                            <div
                                                className="flex justify-center items-center lg:h-[150px] sm:h-[50px] lg:w-[250px] sm:w-[100px]"
                                                style={ { maxWidth: "100%", maxHeight: "100%" } }
                                            >
                                                <img
                                                    src={ clientConfig?.logo }
                                                    alt="logo"
                                                    className="max-h-full max-w-full"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-evenly mt-[2rem] w-full h-full">
                                            <img src={ mainBanneLink } alt="main_banner_link" preview={ false } sizes="large" />
                                            <div>
                                                <h2 className="text-white text-5xl font-bold pr-8 pl-8 leading-[4rem]">{ formConfigData?.heading || dynamicData[client_id]?.default_left_section?.heading || dynamicData?.default?.default_left_section?.heading || "" }</h2>
                                                <p className="text-white text-base pr-8 pl-8 leading-[1.5rem]">{ formConfigData?.sub_heading || dynamicData[client_id]?.default_left_section?.sub_heading || dynamicData?.default?.default_left_section?.sub_heading || "" }</p>
                                            </div>
                                            <p className="text-white text-sm">{ formConfigData?.additional_text || dynamicData[client_id]?.default_left_section?.additional_text || dynamicData?.default?.default_left_section?.additional_text || "" }</p>
                                        </div>
                                    </div>
                                </div>
                        }
                    </>
            }
        </>
    )
};

export default LeftSideBar;
